import styled, { css } from 'styled-components'
import { setLightness } from 'polished'

export default styled.div`
    .ant-select-item-option {
        color: ${({ theme: { isLight, brandColor } }) =>
            isLight ? setLightness(0.3, brandColor) : brandColor};
        &.ant-select-item-option-active:not(.ant-select-item-option-selected) {
            ${({ theme: { isLight, brandColor } }) =>
                isLight
                    ? css`
                          background-color: ${brandColor};
                      `
                    : css`
                          background-color: ${setLightness(0.92, brandColor)};
                          color: ${brandColor};
                      `}
        }
        &.ant-select-item-option-selected {
            ${({ theme: { isLight, brandColor } }) =>
                isLight
                    ? css`
                          background-color: ${setLightness(0.3, brandColor)};
                          color: #fff;
                      `
                    : css`
                          background-color: ${brandColor};
                          color: #fff;
                      `}
        }
    }
`
