export default (
    <svg
        className="system-icon"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M24.037 18.3637C24.3163 18.3637 24.5808 18.2425 24.7615 18.0299C24.9459 17.8211 25.0297 17.5415 24.9906 17.2655L23.2547 5.81104C23.1839 5.34123 22.776 4.99448 22.301 5.00007H6.70004C6.22509 4.99447 5.81717 5.34124 5.74641 5.81104L4.0105 17.2655C3.96953 17.5396 4.04962 17.8193 4.23029 18.0299C4.41282 18.2443 4.68289 18.3674 4.96416 18.3637H13.5469V20.2727H11.6396C10.0601 20.2727 8.7787 21.5554 8.7787 23.1364V25.0454C8.7787 25.299 8.87928 25.5414 9.05809 25.7203C9.23689 25.8993 9.47903 26 9.73235 26H19.2687C19.522 26 19.7642 25.8993 19.943 25.7203C20.1218 25.5414 20.2224 25.299 20.2224 25.0454V23.1364C20.2224 21.5554 18.9409 20.2727 17.3615 20.2727H15.4542V18.3637H24.037ZM18.3151 23.1364V24.0909H10.6861V23.1364C10.6861 22.6087 11.1126 22.1818 11.6397 22.1818H17.3615C17.6148 22.1818 17.857 22.2825 18.0358 22.4615C18.2146 22.6404 18.3152 22.8828 18.3152 23.1364L18.3151 23.1364ZM18.3151 16.4546V12.6364H22.3494L22.9268 16.4546H18.3151ZM22.0626 10.7273H18.3151V6.90916H21.4815L22.0626 10.7273ZM16.4081 10.7273H12.5935V6.90916H16.4081V10.7273ZM10.6863 6.90916V10.7273H6.93875L7.5199 6.90916H10.6863ZM6.07452 16.4546L6.65566 12.5935H6.65753C6.72644 12.6159 6.79908 12.629 6.87172 12.6364H10.6863V16.4546L6.07452 16.4546ZM12.5936 12.6364H16.4081V16.4546H12.5936V12.6364Z"
            fill="#757575"
        />
    </svg>
)
