import styled from 'styled-components'

export default styled.div`
    align-items: center;
    color: #000;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    margin-top: 60px;
    text-align: center;
    & > * {
        width: 256px;
    }
    & > .no-data-image,
    & > .maintenance-mode-image {
        height: 150px;
        width: 180px;
    }
    & > h2 {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        font-weight: 500;
        margin: 24px 0 0;
    }
    & > p {
        color: #757575;
        line-height: 1.5;
        margin: 12px 0 0;
    }
    & > .description {
        color: inherit;
    }
    & > .problems {
        align-items: center;
        display: flex;
        flex-direction: column;
    }
`
