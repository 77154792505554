import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'ttag'
import StyledCard from './AccountSelectCard.styled'
import StyledSelect from '@consumer-dashboard/components/common/Select.styled'
import StyledDropdown from '@consumer-dashboard/components/common/Dropdown.styled'
import { getMonitoredSystems } from '@consumer-dashboard/redux/selectors'
import { fetchDashboard } from '@consumer-dashboard/redux/actions'

export default function AccountSelectCard({ delay }) {
    const dispatch = useDispatch()
    const { systems, selectedSystem } = useSelector(getMonitoredSystems) || {}

    const onSelect = (id) => {
        fetchDashboardData(id)
    }

    const fetchDashboardData = (id) => {
        dispatch(fetchDashboard(id))
    }

    if (systems.length < 2) {
        return null
    }

    return (
        <StyledCard
            title={t`Select account`}
            className="account-select"
            delay={delay}
            data-testid="account-select-card"
        >
            <StyledSelect
                defaultValue={selectedSystem}
                onChange={onSelect}
                size="large"
                data-testid="account-select"
                options={systems.map(({ id, fullName }) => ({
                    label: fullName,
                    value: id
                }))}
                dropdownRender={(children) => <StyledDropdown>{children}</StyledDropdown>}
            />
        </StyledCard>
    )
}

AccountSelectCard.propTypes = {
    delay: PropTypes.number
}

AccountSelectCard.defaultProps = {
    delay: 0
}
