// TODO: Tests

import noop from 'lodash/noop'
import api from '@consumer-dashboard/utils/api'
import {
    FETCH_MONITORED_SYSTEMS,
    FETCH_MONITORED_SYSTEMS_SUCCEEDED,
    FETCH_MONITORED_SYSTEMS_FAILED,
    FETCH_DASHBOARD,
    FETCH_DASHBOARD_SUCCEEDED,
    FETCH_DASHBOARD_FAILED,
    SHOW_LOADING,
    HIDE_LOADING
} from './types'

export const fetchMonitoredSystems = (callback = noop) => async (dispatch) => {
    try {
        dispatch(showLoading())
        dispatch({ type: FETCH_MONITORED_SYSTEMS })

        const payload = await api.fetchMonitoredSystems()
        dispatch({ type: FETCH_MONITORED_SYSTEMS_SUCCEEDED, payload })
        callback(payload?.[0]?.id)
    } catch (error) {
        dispatch({
            type: FETCH_MONITORED_SYSTEMS_FAILED,
            payload: { error, retry: () => dispatch(fetchMonitoredSystems(callback)) }
        })

        dispatch(hideLoading())
    }
}

export const fetchDashboard = (systemId) => async (dispatch) => {
    try {
        dispatch(showLoading())
        dispatch({ type: FETCH_DASHBOARD })

        dispatch({
            type: FETCH_DASHBOARD_SUCCEEDED,
            payload: await api.fetchDashboard(systemId)
        })
    } catch (error) {
        dispatch({
            type: FETCH_DASHBOARD_FAILED,
            payload: { error, retry: () => dispatch(fetchDashboard(systemId)) }
        })

        dispatch(hideLoading())
    }
}

export const showLoading = () => ({
    type: SHOW_LOADING
})

export const hideLoading = () => ({
    type: HIDE_LOADING
})

export default {
    fetchMonitoredSystems,
    fetchDashboard,
    showLoading,
    hideLoading
}
