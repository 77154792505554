import { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { t } from 'ttag'
import { ThemeContext } from 'styled-components'
import { setLightness } from 'polished'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from 'recharts'
import StyledCard from './RecentProdCard.styled'
import { getDashboard } from '@consumer-dashboard/redux/selectors'
import { createFormatters } from 'generic/generic.utils'
import { USER_LANGUAGE } from '@consumer-dashboard/utils/constants'
import LoadingOverlay from '@consumer-dashboard/components/common/LoadingOverlay'
import { fetchRecentProduction } from '@consumer-dashboard/utils/api'
import moment from '@consumer-dashboard/utils/moment'
import EmptyState from '@consumer-dashboard/components/common/EmptyState'

export default function RecentProdCard() {
    const { isLight, brandColor } = useContext(ThemeContext) || {}
    const { selectedSystem } = useSelector(getDashboard)
    const { formatNumber } = createFormatters(USER_LANGUAGE)
    const [graphData, setGraphData] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)

        fetchRecentProduction(selectedSystem)
            .then(({ results }) => setGraphData(results))
            .catch(() => setGraphData(null))
            .finally(() => setLoading(false))
    }, [selectedSystem])

    const formatDate = (date, full = false) => {
        const parsedDate = moment(date).startOf('day').toString()
        const today = moment().startOf('day').toString()
        const yesterday = moment().add(-1, 'day').startOf('day').toString()

        switch (full || parsedDate) {
            case today:
                return t`Production`
            case yesterday:
                return t`Yesterday`
            default:
                return moment(date)
                    .startOf('day')
                    .toDate()
                    .toLocaleDateString(USER_LANGUAGE, {
                        weekday: 'long',
                        ...(full && { day: 'numeric', month: 'long', year: 'numeric' })
                    })
        }
    }

    const renderTick = ({
        payload,
        index: _,
        verticalAnchor: __,
        visibleTicksCount: ___,
        ...attrs
    }) => {
        const today = moment().startOf('day').toString()
        const parsedDate = moment(payload.value).startOf('day').toString()

        return (
            <text
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...attrs}
                style={
                    parsedDate === today
                        ? {
                              fill: isLight ? setLightness(0.4, brandColor) : brandColor,
                              fontWeight: 'bold'
                          }
                        : {}
                }
            >
                <tspan x={attrs.x} dy="0.71em">
                    {formatDate(payload.value)}
                </tspan>
            </text>
        )
    }

    renderTick.propTypes = {
        payload: PropTypes.shape({
            value: PropTypes.string.isRequired
        }).isRequired,
        index: PropTypes.any.isRequired,
        verticalAnchor: PropTypes.any.isRequired,
        visibleTicksCount: PropTypes.any.isRequired
    }

    return (
        <StyledCard
            title={t`Recent production`}
            className="recent-production"
            delay={600}
            data-testid="recent-prod-card"
        >
            {graphData?.length ? (
                <div className="graph">
                    <ResponsiveContainer>
                        <BarChart data={graphData}>
                            <Bar
                                dataKey="resultedYield"
                                name={t`Production`}
                                unit={` ${t`kWh`}`}
                                fill={
                                    isLight ? setLightness(0.4, brandColor) : brandColor
                                }
                            />
                            <CartesianGrid vertical={false} />
                            <XAxis
                                height={20}
                                dataKey="groupedDate"
                                interval="preserveStartEnd"
                                padding={{ left: 10, right: 10 }}
                                axisLine={false}
                                tickLine={false}
                                tickFormatter={formatDate}
                                tick={renderTick}
                            />
                            <YAxis
                                width={50}
                                tickFormatter={formatNumber}
                                axisLine={false}
                                tickLine={false}
                            />
                            <Tooltip
                                separator=": "
                                formatter={formatNumber}
                                labelFormatter={(date) => formatDate(date, true)}
                                cursor={{
                                    fill: brandColor,
                                    fillOpacity: 0.25,
                                    strokeWidth: 0
                                }}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            ) : (
                <EmptyState height="100%" minHeight="233px" />
            )}
            <LoadingOverlay loading={loading} />
        </StyledCard>
    )
}
