import {
    MONKEY_BLACK,
    MONKEY_GORILLA_GREY,
    MONKEY_PLUM,
    MONKEY_WHITE
} from 'generic/theme/colours'
import styled from 'styled-components'

export default styled.div`
    color: ${MONKEY_BLACK};
    display: flex;
    flex-direction: column;

    font-size: 13px;
    min-height: 100vh;
    & > .content {
        flex: 1;
        margin: 0 auto;
        max-width: 100%;
        padding: 12px;
        width: 1024px;
        & > .page {
            padding: 48px 0 4px;
            & > .page-title {
                font-family: 'Montserrat', sans-serif;
                color: ${MONKEY_PLUM};
                font-size: 48px;
                font-weight: 700;
                margin: 0 0 40px;
            }
        }
    }
    @media (max-width: 1023px) {
        & > .content > .page {
            padding: 20px 0 0;
            & > .page-title {
                font-size: 42px;
                margin-bottom: 32px;
                text-align: center;
            }
        }
    }
    @media (max-width: 767px) {
        & > .content {
            padding: 8px;
            & > .page {
                padding: 0;
                & > .page-title {
                    background-color: ${MONKEY_WHITE};
                    color: ${MONKEY_GORILLA_GREY};
                    font-family: inherit;
                    font-size: 17px;
                    margin: -8px -8px 8px;
                    padding: 20px;
                }
            }
        }
    }
`
