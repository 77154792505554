import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import BrandColored from './common/BrandColored'
import Header from './common/Header'
import BottomBar from './common/BottomBar'
import Energy from './energy/Energy'
import System from './system/System'
import Installer from './installer/Installer'
import Account from './account/Account'
import LoadingScreen from './common/LoadingScreen'
import ErrorScreen from './common/ErrorScreen'
import StyledDashboard from './MainRouter.styled'
import { fetchDashboard, fetchMonitoredSystems } from '@consumer-dashboard/redux/actions'
import { getDashboard } from '@consumer-dashboard/redux/selectors'
import ImagesPreloader from './common/ImagesPreloader'
import ScrollToTop from './common/ScrollToTop'
import { changeLanguage } from '@consumer-dashboard/i18ninit'
import { BASE_PATH } from '@consumer-dashboard/utils/constants'

// TODO: Make the parent component handle the card delays,
// like the account page is doing
export default function MainRouter() {
    const dispatch = useDispatch()
    const location = useLocation()
    const { systems, selectedSystem } = useSelector(getDashboard)

    const {
        installer: { brandColor },
        language
    } = systems[selectedSystem] || { installer: {} }

    useEffect(() => {
        // changeLanguage(language || 'en')
        // Temporarily setting language to just dutch
        changeLanguage('nl')
    }, [language])

    useEffect(() => {
        const { monitored_system_id: monitoredSystemId } = queryString.parse(
            location.search
        )

        if (monitoredSystemId) {
            dispatch(fetchDashboard(monitoredSystemId))
            return
        }

        dispatch(fetchMonitoredSystems((id) => dispatch(fetchDashboard(id))))
    }, [])

    return (
        <BrandColored className="dashboard-container" brandColor={brandColor}>
            <StyledDashboard>
                <Header />
                <div className="content">
                    <Routes>
                        <Route path={`${BASE_PATH}energy/`} element={<Energy />} />
                        <Route path={`${BASE_PATH}system/`} element={<System />} />
                        <Route path={`${BASE_PATH}installer/`} element={<Installer />} />
                        <Route path={`${BASE_PATH}account/`} element={<Account />} />
                        <Route
                            path="*"
                            element={<Navigate replace to={`${BASE_PATH}energy/`} />}
                        />
                    </Routes>
                </div>
                <BottomBar />
                <ErrorScreen />
                <LoadingScreen />
                <ScrollToTop />
                <ImagesPreloader />
            </StyledDashboard>
        </BrandColored>
    )
}
