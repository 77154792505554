import { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import StyledCard from './Card.styled'

const ANIMATE_FROM_OPTIONS = ['top', 'right', 'bottom', 'left']

export default function Card({
    title,
    className,
    buttons,
    animateFrom,
    delay,
    children,
    ...props
}) {
    const [show, setShow] = useState(false)

    const renderButtons = () =>
        buttons.map(
            (
                {
                    replace,
                    key,
                    content,
                    className,
                    disabled,
                    onClick,
                    to,
                    href,
                    ...buttonProps
                },
                index
            ) => {
                if (replace) {
                    return <Fragment key={key || index}>{replace}</Fragment>
                }

                const Element = disabled ? 'button' : to ? Link : href ? 'a' : 'button'

                return (
                    <Element
                        key={key || content}
                        type="button"
                        className={className}
                        disabled={disabled}
                        onClick={onClick}
                        to={disabled ? undefined : to}
                        href={disabled ? undefined : href}
                        data-testid="card-button"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...buttonProps}
                    >
                        {content}
                    </Element>
                )
            }
        )

    useEffect(() => {
        const timeout = setTimeout(() => setShow(true), delay)
        return () => clearTimeout(timeout)
    }, [])

    return (
        <CSSTransition in={show} timeout={0} classNames="card">
            <StyledCard
                data-testid="card-component"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                animateFrom={animateFrom}
                delay={delay}
                className={classNames('card', className, { 'card-enter': !show })}
            >
                {(title || !!buttons.length) && (
                    <header className="header">
                        <h3 className="title" data-testid="card-title">
                            {title}
                        </h3>
                        {!!buttons.length && (
                            <div className="buttons">{renderButtons()}</div>
                        )}
                    </header>
                )}
                <article className="content">{children}</article>
            </StyledCard>
        </CSSTransition>
    )
}

Card.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    animateFrom: PropTypes.oneOf(ANIMATE_FROM_OPTIONS),
    delay: PropTypes.number,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            content: PropTypes.node,
            onClick: PropTypes.func
        })
    ),
    children: PropTypes.node
}

Card.defaultProps = {
    title: '',
    className: '',
    animateFrom: ANIMATE_FROM_OPTIONS[1], // 'right'
    delay: 0,
    buttons: [],
    children: null
}
