import { NavLink } from '@consumer-dashboard/components/common/RouterComponents'
import { NAVIGATION, TRANSLATED_NAVIGATION } from '@consumer-dashboard/utils/constants'
import StyledBottomBar from './BottomBar.styled'

export default function BottomBar() {
    return (
        <StyledBottomBar className="bottom-bar hide-on-notebook-and-up">
            {Object.values(NAVIGATION).map(({ name, svg, path }) => (
                <NavLink
                    key={name}
                    to={path}
                    className="bottom-bar-button"
                    activeClassName="active"
                >
                    {svg}
                    {TRANSLATED_NAVIGATION()[name]}
                </NavLink>
            ))}
        </StyledBottomBar>
    )
}
