import { useState } from 'react'
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { Link, NavLink } from '@consumer-dashboard/components/common/RouterComponents'
import { NAVIGATION, TRANSLATED_NAVIGATION } from '@consumer-dashboard/utils/constants'
import StyledHeader from './Header.styled'
import { getDashboard } from '@consumer-dashboard/redux/selectors'
import MenuIcon from '@consumer-dashboard/svgs/menu'
import CloseIcon from '@consumer-dashboard/svgs/close'

export default function Header() {
    const { systems, selectedSystem } = useSelector(getDashboard)
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const systemName = systems?.[selectedSystem]?.fullName

    return (
        <StyledHeader className="header hide-on-tablet-and-down" data-testid="header">
            <button
                type="button"
                className="sidebar-toggler"
                onClick={() => setSidebarOpen(true)}
                data-testid="header-sidebar-toggler"
            >
                {MenuIcon}
            </button>
            <Link to={NAVIGATION.account.path} className="account">
                {!!systemName && (
                    <span data-testid="header-system-name">{systemName}</span>
                )}
                {NAVIGATION.account.svg}
            </Link>
            <CSSTransition in={sidebarOpen} timeout={300} classNames="sidebar">
                <div
                    className="sidebar"
                    onClick={() => setSidebarOpen(false)}
                    data-testid="sidebar"
                >
                    <div className="overlay" data-testid="sidebar-close-area" />
                    <div className="menu" onClick={(e) => e.stopPropagation()}>
                        <header className="sidebar-header">
                            <button
                                type="button"
                                className="close-button"
                                onClick={() => setSidebarOpen(false)}
                                data-testid="sidebar-close-button"
                            >
                                {CloseIcon}
                            </button>
                        </header>
                        {Object.values(NAVIGATION).map(({ name, path }) => (
                            <NavLink
                                key={name}
                                to={path}
                                activeClassName="active"
                                onClick={() => setSidebarOpen(false)}
                            >
                                {TRANSLATED_NAVIGATION()[name]}
                            </NavLink>
                        ))}
                    </div>
                </div>
            </CSSTransition>
        </StyledHeader>
    )
}
