import { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import BrandColored from './common/BrandColored'
import Login from './account/Login'
import StyledContainer from './LoginRouter.styled'
import { changeLanguage } from '@consumer-dashboard/i18ninit'

export default function MainRouter() {
    useEffect(() => {
        changeLanguage('nl')
    }, [])

    return (
        <BrandColored className="login-container">
            <StyledContainer>
                <Router>
                    <Routes>
                        <Route path="/login/" element={<Login />} />
                        <Route path="*" element={<Navigate replace to="login/" />} />
                    </Routes>
                </Router>
            </StyledContainer>
        </BrandColored>
    )
}
