import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { t } from 'ttag'
import StyledCard from './ExplanationCard.styled'
import { getDashboard } from '@consumer-dashboard/redux/selectors'

export default function ExplanationCard({
    delay,
    customTitle,
    customSubtitle,
    customSteps,
    full
}) {
    let domain

    try {
        const { systems, selectedSystem } = useSelector(getDashboard) || {}
        domain = systems[selectedSystem]?.domain
    } catch (e) {
        domain = 'mijn.solar'
    }

    const url = `https://${domain}/`

    const steps = (
        customSteps || [
            ...[
                full && {
                    title: (
                        <span>
                            {t`Go to`}{' '}
                            <a
                                className="brand-link"
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {domain}
                            </a>
                        </span>
                    ),
                    description: [
                        t`You will also find this link in the email you received from your installer.`
                    ]
                }
            ],
            {
                title: t`Enter your email address`,
                description: [t`We will then send you a new link.`]
            },
            {
                title: t`Open your email box`,
                description: [
                    t`Click on the link we sent you.`,
                    t`Don't forget to check your spam box.`
                ]
            },
            {
                title: full ? t`You're logged in again!` : t`You're logged in!`,
                description: [
                    t`Easy, isn't it? All without a password.`,
                    t`To keep it safe, we generate a safe link that is hard to guess.`,
                    t`It also expires automatically after a while. So only you can reach it.`
                ]
            }
        ]
    )
        .filter((s) => s)
        .map(({ title, description }, index) => (
            <div key={title} className="step">
                <div className="step-number" data-testid="explanation-step-number">
                    {index + 1})
                </div>
                <div className="step-text">
                    <p className="step-title" data-testid="explanation-step-title">
                        {title}
                    </p>
                    {description.map((text) => (
                        <p
                            key={text}
                            className="step-description"
                            data-testid="explanation-step-description"
                        >
                            {text}
                        </p>
                    ))}
                </div>
            </div>
        ))

    return (
        <StyledCard className="explanation" delay={delay} data-testid="explanation-card">
            <header className="explanation-header">
                <h3 data-testid="explanation-title">
                    {customTitle || t`Log in without a password`}
                </h3>
                <h2 data-testid="explanation-subtitle">
                    {customSubtitle || t`How does it work?`}
                </h2>
            </header>
            <div className="steps">{steps}</div>
        </StyledCard>
    )
}

ExplanationCard.propTypes = {
    delay: PropTypes.number,
    customTitle: PropTypes.node,
    customSubtitle: PropTypes.node,
    customSteps: PropTypes.arrayOf(
        PropTypes.exact({
            title: PropTypes.node, // eslint-disable-line react/no-unused-prop-types
            description: PropTypes.arrayOf(PropTypes.node) // eslint-disable-line react/no-unused-prop-types
        })
    ),
    full: PropTypes.bool
}

ExplanationCard.defaultProps = {
    delay: 0,
    customTitle: null,
    customSubtitle: null,
    customSteps: null,
    full: false
}
