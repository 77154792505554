import { useSelector } from 'react-redux'
import { t } from 'ttag'
import Panels from './Panels'
import Inverter from './Inverter'
import StyledCard from './SystemElementsCard.styled'
import { getDashboard } from '@consumer-dashboard/redux/selectors'

export default function SystemElementsCard() {
    const { systems, selectedSystem } = useSelector(getDashboard)
    const { panels, inverter } = systems[selectedSystem]
    const [panelsCount, panelWP] = panels?.summary?.split('x') || ['1', '0 WP']
    const [panelWattPeak] = panelWP?.split(' ') || ['0']

    return (
        <StyledCard
            className="system-components"
            delay={400}
            data-testid="system-elements-card"
        >
            {panels ? (
                <>
                    <h2 data-testid="panel-section-title">
                        {parseInt(panelsCount || 1) > 1 ? t`Panels` : t`Panel`}
                    </h2>
                    <Panels
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...panels}
                        count={parseInt(panelsCount)}
                        wattPeak={parseInt(panelWattPeak)}
                    />
                </>
            ) : (
                <h2 data-testid="no-panels">{t`No panels yet`}</h2>
            )}
            {inverter ? (
                <>
                    <h2 data-testid="inverter-section-title">{t`Inverter`}</h2>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <Inverter {...inverter} />
                </>
            ) : (
                <h2 data-testid="no-inverter">{t`No inverter yet`}</h2>
            )}
        </StyledCard>
    )
}
