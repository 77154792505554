import styled from 'styled-components'

export default styled.div`
    font-size: 15px;
    & > .cards {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        & > .log-in,
        & > .link-sent {
            flex-basis: calc(34% - 8px);
            margin-right: 16px;
        }
        & > .explanation {
            flex: 1;
        }
    }
    @media (max-width: 1023px) {
        & > .cards {
            & > .log-in,
            & > .link-sent {
                flex-basis: calc(34% - 6px);
                margin-right: 12px;
            }
        }
    }
    @media (max-width: 767px) {
        & > .cards {
            & > .log-in,
            & > .link-sent,
            & > .explanation {
                flex-basis: 100%;
            }
            & > .log-in,
            & > .link-sent {
                margin-bottom: 8px;
                margin-right: 0;
            }
        }
    }
`
