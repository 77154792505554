import { t } from 'ttag'
import pickBy from 'lodash/pickBy'

export const getOnlyChangedValues = (newValues = {}, initialValues = {}) =>
    pickBy(newValues, (value, key) => value !== initialValues[key])

export const formatNumber = (
    number = '0',
    {
        country = 'NL',
        hideDecimals = false,
        minimumFractionDigits = 0,
        maximumFractionDigits = 2
    } = {}
) => {
    const _number = number || '0'
    const _country = country || 'NL'

    return hideDecimals
        ? parseInt(_number || 0).toLocaleString(_country)
        : parseFloat(_number || 0).toLocaleString(_country, {
              minimumFractionDigits,
              maximumFractionDigits
          })
}

export const formatInteger = (integer = '0', { country = 'NL' } = {}) => {
    const _integer = integer || 0
    const _country = country || 'NL'

    return formatNumber(_integer, { country: _country, hideDecimals: true })
}

export const formatCurrency = (
    number = '0',
    {
        country = 'NL',
        currency = null,
        currencySeparator = ' ',
        hideDecimals = false,
        minimumFractionDigits = 2,
        maximumFractionDigits = 2
    } = {}
) => {
    const _number = number || '0'
    const _country = country || 'NL'

    return `${currency ? `${currency}${currencySeparator}` : ''}${formatNumber(_number, {
        country: _country,
        hideDecimals,
        minimumFractionDigits,
        maximumFractionDigits
    })}`
}

export const formatDate = (date, { country = 'NL', ...options }) => {
    let formattedDate

    try {
        const parsedDate = date instanceof Date ? date : new Date(date)
        formattedDate =
            parsedDate === 'Invalid Date'
                ? t`Invalid date`
                : parsedDate.toLocaleDateString(country, { ...options })
    } catch (e) {
        formattedDate = t`Invalid date`
    }

    return formattedDate
}

export const createFormatters = (country = 'NL') => {
    const _country = country || 'NL'

    return {
        formatNumber: (number, options) =>
            formatNumber(number, { country: _country, ...options }),
        formatInteger: (integer, options) =>
            formatInteger(integer, { country: _country, ...options }),
        formatCurrency: (number, options) =>
            formatCurrency(number, { country: _country, ...options }),
        formatDate: (date, options) => formatDate(date, { country: _country, ...options })
    }
}

export const getFormatterForCountry = (c = 'NL') => createFormatters(c)

export const validateVDValkToken = (token) => {
    try {
        // eslint-disable-next-line prefer-const
        let [header, payload, signature] = token.split('.')
        header = JSON.parse(atob(header))
        payload = JSON.parse(atob(payload))
        return [
            signature,
            header.alg === 'HS256',
            header.typ === 'JWT',
            payload.iss === 'http://vandervalk.ezzing.com/longAuth',
            payload.exp * 1000 > Date.now()
        ].every((x) => x)
    } catch (error) {
        console.warn(error)
        return false
    }
}

/**
 * Capitalize first letter of every word in a phrase.
 *
 * @param {string} phrase
 * @return {string} Titlecased phrase.
 */
export const toTitleCase = (phrase) =>
    phrase
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')

/**
 * Returns ISO 8601 formatted date.
 *
 * @return {string} date string (e.g. '2011-10-05').
 */
export const getCurrentISODate = () => new Date().toISOString().split('T')[0]

/**
 * Replaces placeholders in a string with values from a properties object
 *
 * @param {string} string - The template string with placeholders
 * @param {Object.<string, any>} placeholdersObject - The object containing
 * property-value pairs
 * @returns {string} The updated string with placeholders replaced by values
 * from the properties object
 */
export const template = (string, placeholdersObject) => {
    let updatedString = string
    for (const property of Object.keys(placeholdersObject)) {
        updatedString = updatedString.replace(
            new RegExp('{' + property + '}', 'g'),
            placeholdersObject[property]
        )
    }
    return updatedString
}

/**
 * Replaces tag signs with HTML entities in order to avoid XSS execution.
 *
 * @param {string} input - html structure as a string
 */
export const sanitizeHTML = (input) => input.replace(/</g, '&lt;').replace(/>/g, '&gt;')

/**
 * Converts a decimal number to a percentage string.
 *
 * @param {number} decimal - The decimal number to be converted to a percentage.
 * @returns {string} A string representing the decimal as a percentage (e.g., "19%").
 *
 * @example
 * const decimalValue = 0.19;
 * const percentageString = decimalToPercentage(decimalValue);
 * percentageString will be "19%".
 */
export function decimalToPercentage(decimal) {
    // Returns "-" if the input is not invalid number or less than zero
    if (typeof decimal !== 'number' || isNaN(decimal)) {
        return ' - '
    }
    const percentage = (decimal * 100).toFixed(0)
    return percentage + '%'
}

/**
 * Generates a new ID for a list of elements by finding the largest existing ID in the
 * list and incrementing it by 1.
 *
 * Only use this in places where concurrency is not an issue!
 *
 * @param {Array} elements - An array of elements with IDs.
 * @param {Object} elements.id - The ID of each element in the array, must be a
 * non-negative real number.
 * @returns {number} The new ID for the list, which is one greater than the largest
 * existing ID in the list.
 */
export function generateIdFromList(elements) {
    if (!elements.length) return 1
    const IDs = elements.map((element) => element.id)
    const newID = Math.max(...IDs) + 1
    return newID
}

export default {
    getOnlyChangedValues,
    getFormatterForCountry,
    formatNumber,
    formatInteger,
    formatCurrency,
    formatDate,
    createFormatters,
    toTitleCase,
    getCurrentISODate
}
