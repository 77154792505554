import styled from 'styled-components'
import { setLightness, transparentize } from 'polished'
import Card from '@consumer-dashboard/components/common/Card'
import { defaultColor } from '@consumer-dashboard/components/common/BrandColored'

export default styled(Card)`
    & > .header > .buttons > button > .calendar-icon {
        margin-top: -4px;
    }
    & > .content {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        position: relative;
        & > .graph-area {
            align-items: stretch;
            display: flex;
            height: 300px;
            margin-top: 32px;
            & > .graph-container {
                flex: 1;
            }
            & > .arrow {
                align-items: center;
                background-color: transparent;
                border: none;
                color: ${({ theme: { isLight, brandColor } }) =>
                    isLight ? setLightness(0.4, brandColor || defaultColor) : brandColor};
                display: flex;
                font-size: 20px;
                justify-content: center;
                transition: background-color 300ms, opacity 300ms;
                opacity: 0.5;
                width: 30px;
                &:not([disabled]):hover {
                    background-color: ${({ theme }) =>
                        transparentize(0.75, theme.brandColor || defaultColor)};
                    opacity: 1;
                }
                &[disabled] {
                    cursor: not-allowed;
                    color: #aaa;
                }
            }
            & > .loading {
                align-items: center;
                background-color: rgba(255, 255, 255, 0.75);
                display: flex;
                height: 100%;
                justify-content: center;
                left: 0;
                position: absolute;
                top: 0;
                transition: all 600ms;
                width: 100%;
                &:not([class*='loading-enter'], [class*='loading-exit']) {
                    display: none;
                    opacity: 0;
                }
                &.loading-enter {
                    opacity: 0;
                }
                &.loading-enter-active,
                &.loading-exit {
                    opacity: 1;
                }
                &.loading-exit-active {
                    opacity: 0;
                }
                &.loading-exit-done {
                    display: none;
                }
            }
        }
        & > .empty-state {
            margin-top: 16px;
        }
    }
    @media (max-width: 767px) {
        & > .content > .button-group {
            justify-content: center;
        }
    }
`
