import PropTypes from 'prop-types'
import { t } from 'ttag'
import StyledSystemElement from './SystemElement.styled'
import Image from '@consumer-dashboard/components/common/Image'
import { PRELOADED_IMAGES } from '@consumer-dashboard/utils/constants'

export default function Inverter({ model, specsheet, description }) {
    return (
        <StyledSystemElement data-testid="system-inverter">
            {!!model && (
                <h3
                    className="title hide-on-tablet-and-up"
                    data-testid="inverter-model-mobile"
                >
                    {model}
                </h3>
            )}
            <div className="element">
                <div className="image-container">
                    <Image
                        src={PRELOADED_IMAGES.inverter}
                        alt={t`Inverter icon`}
                        className="image"
                    />
                </div>
                <div className="info">
                    {!!model && (
                        <h3
                            className="title hide-on-mobile"
                            data-testid="inverter-model-desktop"
                        >
                            {model}
                        </h3>
                    )}
                    {!!description && (
                        <p className="description" data-testid="inverter-description">
                            {description}
                        </p>
                    )}
                    {!!specsheet && (
                        <a
                            className="specsheet brand-link"
                            href={specsheet}
                            target="_blank"
                            rel="noopener noreferrer"
                            data-testid="inverter-specsheet"
                        >{t`Download specsheet`}</a>
                    )}
                </div>
            </div>
        </StyledSystemElement>
    )
}

Inverter.propTypes = {
    model: PropTypes.string.isRequired,
    specsheet: PropTypes.string,
    description: PropTypes.string
}

Inverter.defaultProps = {
    specsheet: '',
    description: ''
}
