import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { t } from 'ttag'
import { Experiment } from 'experiments/experiments'
import KpiCards from './KpiCards'
import RecentProdCard from './RecentProdCard'
import ProdCard from './ProdCard'
import ProdYearCard from './ProdYearCard'
import NoData from '@consumer-dashboard/components/common/NoData'
import MaintenanceMode from '@consumer-dashboard/components/common/MaintenanceMode'
import StyledEnergy from './Energy.styled'
import { getDashboard, getLoading, getError } from '@consumer-dashboard/redux/selectors'
import ZonnegarantLogo from '@consumer-dashboard/svgs/zonnegarant-logo'

export default function Energy({ customDate }) {
    const {
        dashboard: { systems, selectedSystem },
        loading,
        error
    } = useSelector((s) => ({
        dashboard: getDashboard(s),
        loading: getLoading(s),
        error: getError(s).error
    }))

    if (!Object.keys(systems).length || loading || error) {
        return null
    }

    const { energy, isGuarantee } = systems[selectedSystem]

    return (
        <StyledEnergy className="energy page">
            <h1 className="page-title">{t`My energy production`}</h1>
            <Experiment name="dashboard_maintenance">
                <MaintenanceMode />
            </Experiment>
            <Experiment name="dashboard_maintenance" replace>
                {energy ? (
                    <>
                        <div className="cards">
                            <KpiCards />
                            <RecentProdCard />
                            <ProdCard customDate={customDate} />
                            {isGuarantee && <ProdYearCard />}
                        </div>
                        {isGuarantee && (
                            <span className="powered-by">
                                <span>{t`Monitoring powered by`}&ensp;</span>
                                {ZonnegarantLogo}
                            </span>
                        )}
                    </>
                ) : (
                    <NoData />
                )}
            </Experiment>
        </StyledEnergy>
    )
}

Energy.propTypes = {
    customDate: PropTypes.any
}

Energy.defaultProps = {
    customDate: undefined
}
