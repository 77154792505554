export default (
    <svg
        className="zonnegarant-logo"
        width="107"
        height="16"
        viewBox="0 0 107 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.61327 14.7813C11.5252 14.7813 14.6978 11.6569 14.6978 7.80451C14.6978 3.95213 11.5252 0.827765 7.61327 0.827765C3.7014 0.827765 0.528771 3.95213 0.528771 7.80451C0.528771 11.6569 3.7014 14.7813 7.61327 14.7813ZM7.61327 15.307C3.40878 15.307 0 11.9501 0 7.80956C0 3.66902 3.40878 0.307037 7.61327 0.307037C11.8178 0.307037 15.2265 3.66396 15.2265 7.80451C15.2265 11.9451 11.8178 15.307 7.61327 15.307ZM14.2306 10.2919C13.9123 11.121 13.4349 11.8743 12.8342 12.5163L2.92621 2.75395L14.2306 10.2919ZM9.76943 14.4526C10.7551 14.1442 11.6484 13.6235 12.3927 12.9562L2.38717 3.09773C2.3769 3.10785 2.36664 3.11796 2.35637 3.13312L9.76943 14.4526ZM2.24343 12.3546C1.17562 11.1362 0.533905 9.54364 0.533905 7.80956C0.533905 6.98044 0.682783 6.18671 0.949735 5.44859L2.24343 12.3546ZM6.16044 14.6346C6.6276 14.7307 7.11531 14.7813 7.61327 14.7813C8.13691 14.7813 8.64515 14.7256 9.13285 14.6195L1.9354 3.63363C1.92 3.65385 1.9046 3.67407 1.8892 3.6943L6.16044 14.6346ZM5.41092 14.4375C4.52792 14.1544 3.72193 13.6994 3.02888 13.123L1.4015 4.45264C1.42203 4.41219 1.44257 4.3768 1.46824 4.33636L5.41092 14.4375ZM12.4852 2.7489C11.2171 1.56083 9.49734 0.832821 7.60814 0.832821C6.72001 0.832821 5.86782 0.9946 5.08236 1.28783L12.4852 2.7489ZM14.6413 6.94C14.6772 7.22311 14.6978 7.51128 14.6978 7.80451C14.6978 8.45163 14.6105 9.07852 14.4411 9.67509L3.31637 2.26356C3.32664 2.2585 3.33177 2.25345 3.34204 2.24334L14.6413 6.94ZM14.5078 6.21199C14.2717 5.21604 13.8148 4.30097 13.1987 3.51735L4.12749 1.73272C4.07102 1.76305 4.01455 1.79844 3.95808 1.82878L14.5078 6.21199Z"
            fill="#757575"
        />
        <path
            d="M28.0906 11.037V11.8752H20.4645V11.2046L26.6334 3.54529H20.5602V2.70706H27.9417V3.37765L21.7834 11.037H28.0906Z"
            fill="#757575"
        />
        <path
            d="M30.8773 11.4875C30.3348 11.1837 29.9094 10.7645 29.6009 10.2406C29.2925 9.70627 29.1329 9.10903 29.1329 8.43845C29.1329 7.76786 29.2925 7.16015 29.6009 6.63625C29.9094 6.10188 30.3348 5.69324 30.8773 5.38938C31.4197 5.096 32.026 4.94931 32.696 4.94931C33.3661 4.94931 33.9724 5.096 34.5148 5.38938C35.0573 5.68276 35.4827 6.10188 35.7805 6.63625C36.089 7.17062 36.2379 7.76786 36.2379 8.43845C36.2379 9.10903 36.089 9.71675 35.7805 10.2406C35.4721 10.775 35.0466 11.1941 34.5148 11.4875C33.9724 11.7914 33.3661 11.9381 32.696 11.9381C32.026 11.9485 31.4091 11.7914 30.8773 11.4875ZM34.0256 10.7855C34.4191 10.5655 34.7275 10.2511 34.9509 9.83201C35.1743 9.42337 35.2806 8.95186 35.2806 8.42797C35.2806 7.90407 35.1743 7.43257 34.9509 7.02393C34.7275 6.61529 34.4191 6.30096 34.0256 6.07044C33.632 5.85041 33.1853 5.73515 32.6854 5.73515C32.1855 5.73515 31.7388 5.85041 31.3453 6.07044C30.9517 6.29048 30.6433 6.60482 30.4199 7.02393C30.1965 7.43257 30.0796 7.90407 30.0796 8.42797C30.0796 8.95186 30.1965 9.42337 30.4199 9.83201C30.6433 10.2406 30.9517 10.555 31.3453 10.7855C31.7388 11.0055 32.1855 11.1208 32.6854 11.1208C33.1853 11.1208 33.632 11.0055 34.0256 10.7855Z"
            fill="#757575"
        />
        <path
            d="M43.8214 5.69331C44.3426 6.19624 44.5978 6.92969 44.5978 7.88317V11.8752H43.6512V7.97747C43.6512 7.26498 43.4704 6.72013 43.1088 6.34293C42.7471 5.96573 42.226 5.77713 41.5559 5.77713C40.8007 5.77713 40.2051 5.99716 39.769 6.43723C39.3329 6.8773 39.1096 7.48502 39.1096 8.26038V11.8752H38.163V4.99129H39.067V6.25911C39.3223 5.84 39.6839 5.51518 40.1413 5.28467C40.5986 5.05416 41.1304 4.9389 41.7261 4.9389C42.6089 4.9389 43.3109 5.19037 43.8214 5.69331Z"
            fill="#757575"
        />
        <path
            d="M52.8195 5.69331C53.3407 6.19624 53.596 6.92969 53.596 7.88317V11.8752H52.6494V7.97747C52.6494 7.26498 52.4686 6.72013 52.1069 6.34293C51.7453 5.96573 51.2241 5.77713 50.5541 5.77713C49.7989 5.77713 49.2033 5.99716 48.7672 6.43723C48.3311 6.8773 48.1077 7.48502 48.1077 8.26038V11.8752H47.1611V4.99129H48.0652V6.25911C48.3205 5.84 48.6821 5.51518 49.1394 5.28467C49.5968 5.05416 50.1286 4.9389 50.7242 4.9389C51.607 4.9389 52.309 5.19037 52.8195 5.69331Z"
            fill="#757575"
        />
        <path
            d="M62.2538 8.72132H56.4039C56.4571 9.43382 56.7337 10.0206 57.2442 10.4607C57.7441 10.9007 58.3823 11.1208 59.1587 11.1208C59.5948 11.1208 59.9883 11.0474 60.3499 10.8903C60.7116 10.7331 61.0306 10.5131 61.2965 10.2197L61.8284 10.8169C61.5199 11.1836 61.1264 11.4665 60.669 11.6551C60.201 11.8437 59.6905 11.9485 59.1374 11.9485C58.4248 11.9485 57.7866 11.8018 57.2335 11.498C56.6805 11.1941 56.2444 10.775 55.9359 10.2511C55.6275 9.71673 55.468 9.11949 55.468 8.4489C55.468 7.77831 55.6169 7.17059 55.9147 6.64669C56.2125 6.11232 56.6167 5.70368 57.1378 5.39982C57.659 5.10644 58.2333 4.95975 58.8821 4.95975C59.531 4.95975 60.1053 5.10644 60.6265 5.39982C61.137 5.6932 61.5412 6.11232 61.839 6.63622C62.1262 7.16011 62.2751 7.76783 62.2751 8.4489L62.2538 8.72132ZM57.1804 6.37427C56.723 6.79339 56.4678 7.34872 56.4039 8.02978H61.3604C61.2965 7.34872 61.0413 6.79339 60.5839 6.37427C60.1266 5.95515 59.5629 5.73511 58.8715 5.73511C58.2014 5.73511 57.6377 5.94467 57.1804 6.37427Z"
            fill="#757575"
        />
        <path
            d="M70.667 4.99127V11.037C70.667 12.2105 70.3798 13.0697 69.7948 13.6355C69.2098 14.2013 68.3377 14.4842 67.1677 14.4842C66.5189 14.4842 65.902 14.3899 65.3276 14.2013C64.7427 14.0127 64.2747 13.7508 63.913 13.426L64.3917 12.7239C64.732 13.0173 65.1362 13.2478 65.6255 13.4155C66.1041 13.5831 66.6146 13.6669 67.1464 13.6669C68.0292 13.6669 68.6887 13.4679 69.1035 13.0592C69.5183 12.6506 69.731 12.0219 69.731 11.1627V10.2826C69.4438 10.7227 69.0503 11.0475 68.5823 11.278C68.1037 11.5085 67.5825 11.6133 67.0082 11.6133C66.3487 11.6133 65.7531 11.4666 65.2213 11.1837C64.6895 10.9008 64.264 10.5026 63.9556 9.98921C63.6471 9.4758 63.4982 8.89952 63.4982 8.24989C63.4982 7.60026 63.6471 7.02398 63.9556 6.52104C64.264 6.0181 64.6788 5.61994 65.2106 5.34752C65.7425 5.06462 66.3381 4.92841 67.0082 4.92841C67.6038 4.92841 68.1356 5.04366 68.6142 5.28465C69.0928 5.52564 69.4757 5.86094 69.7736 6.30101V4.99127H70.667ZM68.4547 10.4712C68.8588 10.2616 69.1779 9.95778 69.4013 9.5701C69.6247 9.18242 69.7416 8.75283 69.7416 8.26037C69.7416 7.76791 69.6247 7.33831 69.4013 6.96111C69.1779 6.58391 68.8588 6.28005 68.4547 6.07049C68.0505 5.86093 67.5931 5.74568 67.0826 5.74568C66.5827 5.74568 66.1254 5.85046 65.7212 6.06002C65.317 6.26957 65.0086 6.56295 64.7746 6.95063C64.5512 7.33832 64.4342 7.76791 64.4342 8.26037C64.4342 8.75283 64.5512 9.18242 64.7746 9.5701C64.9979 9.95778 65.317 10.2512 65.7212 10.4712C66.1254 10.6808 66.5827 10.796 67.0826 10.796C67.5931 10.796 68.0505 10.6808 68.4547 10.4712Z"
            fill="#757575"
        />
        <path
            d="M77.857 5.60947C78.3463 6.06002 78.5909 6.72013 78.5909 7.61075V11.8752H77.6868V10.8065C77.4741 11.1627 77.1656 11.4456 76.7508 11.6447C76.336 11.8438 75.8468 11.9486 75.2831 11.9486C74.5066 11.9486 73.8791 11.76 73.4218 11.4037C72.9644 11.037 72.7304 10.555 72.7304 9.94731C72.7304 9.36055 72.9431 8.88904 73.3792 8.5328C73.8047 8.17655 74.496 7.99843 75.432 7.99843H77.6549V7.57931C77.6549 6.98208 77.4847 6.53153 77.155 6.22767C76.8147 5.91333 76.3254 5.76664 75.6766 5.76664C75.2299 5.76664 74.8044 5.83999 74.4003 5.98668C73.9961 6.13337 73.6451 6.33245 73.3473 6.58392L72.9219 5.89238C73.2728 5.599 73.6983 5.36848 74.1982 5.20084C74.6981 5.04367 75.2192 4.95985 75.7617 4.95985C76.6658 4.93889 77.3677 5.15893 77.857 5.60947ZM76.7934 10.8484C77.1763 10.6074 77.4528 10.2616 77.6443 9.8111V8.68997H75.4532C74.262 8.68997 73.6557 9.0986 73.6557 9.91588C73.6557 10.314 73.8153 10.6388 74.1237 10.8694C74.4322 11.0999 74.8683 11.2151 75.4213 11.2151C75.9531 11.2151 76.4105 11.0894 76.7934 10.8484Z"
            fill="#757575"
        />
        <path
            d="M83.0474 5.29515C83.4835 5.06464 84.0259 4.9389 84.6747 4.9389V5.84L84.4514 5.82952C83.7175 5.82952 83.1431 6.04956 82.7283 6.5001C82.3135 6.94017 82.1008 7.56884 82.1008 8.37564V11.8857H81.1542V4.99129H82.0582V6.34294C82.2816 5.88191 82.6113 5.52566 83.0474 5.29515Z"
            fill="#757575"
        />
        <path
            d="M91.0246 5.60947C91.5138 6.06002 91.7585 6.72013 91.7585 7.61075V11.8752H90.8544V10.8065C90.6417 11.1627 90.3332 11.4456 89.9184 11.6447C89.5036 11.8438 89.0143 11.9486 88.4506 11.9486C87.6742 11.9486 87.0466 11.76 86.5893 11.4037C86.1319 11.037 85.8979 10.555 85.8979 9.94731C85.8979 9.36055 86.1107 8.88904 86.5468 8.5328C86.9722 8.17655 87.6635 7.99843 88.5995 7.99843H90.8118V7.57931C90.8118 6.98208 90.6417 6.53153 90.3119 6.22767C89.9716 5.91333 89.4823 5.76664 88.8335 5.76664C88.3868 5.76664 87.9614 5.83999 87.5572 5.98668C87.153 6.13337 86.802 6.33245 86.5042 6.58392L86.0788 5.89238C86.4298 5.599 86.8552 5.36848 87.3551 5.20084C87.855 5.04367 88.3762 4.95985 88.9186 4.95985C89.844 4.93889 90.5353 5.15893 91.0246 5.60947ZM89.9609 10.8484C90.3438 10.6074 90.6204 10.2616 90.8118 9.8111V8.68997H88.6208C87.4296 8.68997 86.8233 9.0986 86.8233 9.91588C86.8233 10.314 86.9828 10.6388 87.2913 10.8694C87.5997 11.0999 88.0358 11.2151 88.5889 11.2151C89.1207 11.2151 89.578 11.0894 89.9609 10.8484Z"
            fill="#757575"
        />
        <path
            d="M99.9802 5.69331C100.501 6.19624 100.757 6.92969 100.757 7.88317V11.8752H99.81V7.97747C99.81 7.26498 99.6292 6.72013 99.2675 6.34293C98.9059 5.96573 98.3847 5.77713 97.7147 5.77713C96.9595 5.77713 96.3639 5.99716 95.9278 6.43723C95.4917 6.8773 95.2684 7.48502 95.2684 8.26038V11.8752H94.3217V4.99129H95.2258V6.25911C95.4811 5.84 95.8427 5.51518 96.3001 5.28467C96.7574 5.05416 97.2892 4.9389 97.8848 4.9389C98.757 4.9389 99.459 5.19037 99.9802 5.69331Z"
            fill="#757575"
        />
        <path
            d="M107 11.456C106.819 11.6132 106.606 11.7284 106.341 11.8122C106.075 11.8961 105.809 11.938 105.522 11.938C104.862 11.938 104.362 11.7599 104.011 11.4141C103.66 11.0683 103.479 10.5759 103.479 9.93671V5.777H102.235V4.99116H103.479V3.48235H104.426V4.99116H106.553V5.777H104.426V9.88432C104.426 10.293 104.532 10.6073 104.735 10.8168C104.947 11.0264 105.245 11.1417 105.628 11.1417C105.819 11.1417 106.011 11.1102 106.192 11.0474C106.372 10.9845 106.532 10.9007 106.66 10.7854L107 11.456Z"
            fill="#757575"
        />
    </svg>
)
