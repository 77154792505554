import pickBy from 'lodash/pickBy';
let _settings = window._settings || {};
_settings.EXPERIMENTS = {
    ..._settings.EXPERIMENTS,
    ...pickBy(window.user || {}, (_, key) => key.startsWith('uses_'))
};
/**
 * Get the base settings of the application.
 *
 * The settings originate from window._settings, which is set by the backend. (Unless in test and overwritten by ``overwriteSettings()``.
 */
export function getSettings() {
    return _settings;
}
/**
 * Override the base settings of the application.
 *
 * For use in tests only. In the running application the settings are set at boot from
 * ``window._settings``. In test the settings are an empty object, so if you test code
 * that uses settings, you are responsible for setting them up correctly using this
 * method yourself.
 *
 * Example::
 *
 *    describe('Systems list component ', () => {
 *        beforeAll(() => {
 *            overrideTestSettings({
 *                EXPERIMENTS: { monitoring_products: false }
 *            })
 *        })
 *        afterAll(() => {
 *            overrideTestSettings({ EXPERIMENTS: {} })
 *        })
 * @param {Object} override
 */
export function overrideTestSettings(override) {
    _settings = { ..._settings, ...override };
}
