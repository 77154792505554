import PropTypes from 'prop-types'
import { t } from 'ttag'
import Image from '@consumer-dashboard/components/common/Image'
import { PRELOADED_IMAGES } from '@consumer-dashboard/utils/constants'
import StyledCard from './LoginCard.styled'

export default function LinkSentCard({ delay, onBack, isHelpOpen, setHelp }) {
    return (
        <StyledCard className="link-sent" delay={delay} data-testid="link-sent-card">
            <Image
                src={PRELOADED_IMAGES['email-sent']}
                alt={t`Email sent image`}
                className="email-sent-image"
            />
            <h3 className="title">{t`Email sent!`}</h3>
            <p>{t`If the email provided is known to us, we've just sent you an email.`}</p>
            <div className="help">
                <p className="title">{t`Not working?`}</p>
                <button
                    type="button"
                    className="brand-link"
                    onClick={onBack}
                    data-testid="email-link-try-again-button"
                >
                    {t`Try again`}
                </button>
                <button
                    type="button"
                    className="brand-link"
                    onClick={() => setHelp(!isHelpOpen)}
                    data-testid={
                        isHelpOpen
                            ? 'link-sent-try-again-button'
                            : 'link-sent-help-button'
                    }
                >
                    {isHelpOpen ? t`Back to login steps` : t`Help with login`}
                </button>
            </div>
        </StyledCard>
    )
}

LinkSentCard.propTypes = {
    delay: PropTypes.number,
    onBack: PropTypes.func.isRequired,
    isHelpOpen: PropTypes.bool.isRequired,
    setHelp: PropTypes.func.isRequired
}

LinkSentCard.defaultProps = {
    delay: 0
}
