/*
 * Experiments are loaded directly from settings as they are usually used to initialize
 * different components. Setting this in the state is then too late.
 */
import PropTypes from 'prop-types'
import { getSettings } from 'settings.singleton'

export function getVariant(experiment) {
    return getSettings().EXPERIMENTS?.[experiment] || 0
}

/**
 * Show or hide children depending on the availability of the experiment.
 *
 * prop.name {string} Render children if given experiment is enabled.
 * prop.replace {bool} Reverses the behaviour. If true, hides the wrapped content when
 * the experiment is enabled.
 * @returns node
 */
export function Experiment({ name, children, replace }) {
    const featureEnabled = getVariant(name)
    const showChildren = replace ? !featureEnabled : featureEnabled
    return showChildren ? children : null
}
Experiment.defaultProps = {
    replace: false
}
Experiment.propTypes = {
    name: PropTypes.string.isRequired,
    replace: PropTypes.bool,
    children: PropTypes.node.isRequired
}
