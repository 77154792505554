// TODO: Tests

export const extractHostname = (url) => new URL(url).hostname

export const extractPhoneNumber = (phone) => phone.replace(/[^\d+]/g, '')

export const dateForRequest = (date) => new Date(date).toISOString().split('T')[0]

export default {
    extractHostname,
    extractPhoneNumber,
    dateForRequest
}
