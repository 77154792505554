import { BASE_PATH } from './constants'

export default {
    'monitored-systems': `${BASE_PATH}get-monitored-systems/`,
    'dashboard-data': `${BASE_PATH}get-dashboard-data/:systemId/`,
    'production-data': `${BASE_PATH}get-production-data/:systemId/`,
    'performance-data': `${BASE_PATH}get-performance-data/:systemId/`,
    'send-magic-link': `${BASE_PATH}send-magic-link`,
    logout: `${BASE_PATH}logout`
}
