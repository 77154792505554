export default (
    <svg
        className="menu"
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <line y1="1" x2="19" y2="1" stroke="black" strokeWidth="2" />
        <line y1="9" x2="19" y2="9" stroke="black" strokeWidth="2" />
        <line y1="17" x2="19" y2="17" stroke="black" strokeWidth="2" />
    </svg>
)
