import styled from 'styled-components'
import { transparentize } from 'polished'
import Card from '@consumer-dashboard/components/common/Card'
import { defaultColor } from '@consumer-dashboard/components/common/BrandColored'

export default styled(Card)`
    position: relative;
    & > .content {
        align-items: center;
        display: flex;
        flex-direction: column;
        text-align: center;
        & > .magic-link-image,
        & > .email-sent-image {
            height: 150px;
            width: 180px;
        }
        & > .title {
            font-family: 'Montserrat', sans-serif;
            font-size: 25px;
            font-weight: 500;
            margin: 16px 0;
        }
        & > p {
            line-height: 1.5;
            margin: 0;
        }
        & > .log-in-form {
            align-items: stretch;
            align-self: center;
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            max-width: 300px;
            width: 100%;
            & > .error {
                color: #ed8484;
                display: none;
                font-size: 12px;
                margin: 4px 0 0;
            }
            & > .email-input {
                background-color: #fff;
                border: 1px solid #bdbdbd;
                border-radius: 3px;
                padding: 8px 12px;
                &.touched.invalid:not(:focus) {
                    border-color: #ed8484;
                    & + .error {
                        display: initial;
                    }
                }
                &:hover,
                &:focus {
                    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3) inset;
                }
                &:focus {
                    border-color: ${({ theme }) => theme.brandColor};
                    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3) inset,
                        0 0 0 2px
                            ${({ theme }) =>
                                transparentize(0.7, theme.brandColor || defaultColor)};
                    transition: border-color 300ms, box-shadow 300ms;
                }
            }
            & > .send-button {
                margin-top: 8px;
            }
        }
        & > .help {
            align-items: center;
            color: #757575;
            display: flex;
            flex-direction: column;
            font-size: 14px;
            margin-top: 20px;
            & > button {
                margin-top: 4px;
            }
            & > .title {
                font-weight: 700;
                margin: 0;
                & + button {
                    margin-top: 8px;
                }
            }
        }
    }
`
