import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { getLuminance } from 'polished'
import StyledBrandColored from './BrandColored.styled'

export const defaultColor = '#ff9328'

const isColor = (color) => {
    const style = new Option().style
    style.color = color
    return style.color !== ''
}

// TODO: Better theming
export default function BrandColored({ className, brandColor, children }) {
    const isValid = isColor(brandColor)
    const validBrandColor = isValid ? brandColor : defaultColor
    const isLight = getLuminance(validBrandColor) > 0.3
    const theme = { isLight, isDefault: !isValid, brandColor: validBrandColor }

    return (
        <ThemeProvider theme={theme}>
            <StyledBrandColored
                className={className}
                brandColor={validBrandColor}
                isLight={isLight}
            >
                {children}
            </StyledBrandColored>
        </ThemeProvider>
    )
}

BrandColored.propTypes = {
    className: PropTypes.string,
    brandColor: PropTypes.string,
    children: PropTypes.node
}

BrandColored.defaultProps = {
    className: '',
    brandColor: defaultColor,
    children: null
}
