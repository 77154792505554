import { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { t } from 'ttag'
import { ThemeContext } from 'styled-components'
import classNames from 'classnames'
import { setLightness } from 'polished'
import { ConfigProvider, DatePicker } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from 'recharts'
import ButtonGroup from '@consumer-dashboard/components/common/ButtonGroup'
import StyledCard from './ProdCard.styled'
import { getDashboard } from '@consumer-dashboard/redux/selectors'
import { ANTD_LOCALES, USER_LANGUAGE } from '@consumer-dashboard/utils/constants'
import { fetchProduction } from '@consumer-dashboard/utils/api'
import { createFormatters } from 'generic/generic.utils'
import LoadingOverlay from '@consumer-dashboard/components/common/LoadingOverlay'
import { moment, momentForRequest } from '@consumer-dashboard/utils/moment'
import EmptyState from '@consumer-dashboard/components/common/EmptyState'

const PERIODS = ['day', 'week', 'month', 'year']
const LIMIT = 10

export default function ProdCard({ customDate }) {
    const { isLight, brandColor } = useContext(ThemeContext) || {}
    const { selectedSystem } = useSelector(getDashboard)
    const [period, setPeriod] = useState(0)
    const [date, dangerouslySetDate] = useState(
        customDate || moment().startOf('day').toDate()
    )
    const [graphData, setGraphData] = useState()
    const [loading, setLoading] = useState(false)
    const [abortController, setAbortController] = useState(new AbortController())
    const { formatNumber } = createFormatters(USER_LANGUAGE)
    const maxPeriodsAfter = Math.floor((LIMIT - 1) / 2)
    const canShiftRight =
        moment(date).diff(moment().startOf('day'), PERIODS[period]) < -maxPeriodsAfter

    useEffect(() => {
        setLoading(true)
        const [fromDate, toDate] = getDateRange()
        abortController.abort()
        const newAbortController = generateNewAbortController()

        fetchProduction(
            selectedSystem,
            PERIODS[period],
            fromDate,
            toDate,
            newAbortController.signal
        )
            .then(({ results }) => setGraphData(results))
            .catch(() => setGraphData(null))
            .finally(() => setLoading(false))

        return () => newAbortController.abort()
    }, [date.toString(), period, selectedSystem])

    const generateNewAbortController = () => {
        const newAbortController = new AbortController()
        setAbortController(newAbortController)
        return newAbortController
    }

    const setDate = (date) => {
        let newDate = moment(date).startOf('day')
        const today = moment().startOf('day')

        if (newDate.diff(today, 'day') > 0) {
            newDate = today
        }

        dangerouslySetDate(newDate.toDate())
    }

    const getDateRange = () => {
        let actualDate = momentForRequest(date).startOf(PERIODS[period]).startOf('day')
        const endPeriod = momentForRequest().startOf(PERIODS[period]).startOf('day')

        if (actualDate.diff(endPeriod, PERIODS[period]) > 0) {
            actualDate = endPeriod.clone()
        }

        const periodsFromEnd = endPeriod.diff(actualDate, PERIODS[period])
        const upperLimit = Math.min(periodsFromEnd, maxPeriodsAfter)
        const lowerLimit = -LIMIT + upperLimit + 1

        return [
            actualDate.clone().add(lowerLimit, PERIODS[period]).toDate(),
            actualDate
                .clone()
                .add(upperLimit, PERIODS[period])
                .endOf(PERIODS[period])
                .toDate()
        ]
    }

    const formatDate = (date, full = false) =>
        moment(date)
            .toDate()
            .toLocaleDateString(USER_LANGUAGE, {
                ...(period === 0 && full && { weekday: 'long' }),
                ...(period <= 1 && { day: 'numeric' }),
                ...(period <= 2 && {
                    month: full ? 'long' : period <= 1 ? 'numeric' : 'short'
                }),
                ...((period === 3 || full) && { year: 'numeric' })
            })

    const shiftDate = (side = 'left') => {
        const sign = side === 'left' ? -1 : 1
        setDate(moment(date).add(sign * LIMIT, PERIODS[period]))
    }

    const renderTick = ({
        payload,
        index: _,
        verticalAnchor: __,
        visibleTicksCount: ___,
        ...attrs
    }) => {
        const isCurrentPeriod =
            moment(date).startOf(PERIODS[period]).diff(moment(payload.value)) === 0

        return (
            <text
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...attrs}
                style={
                    isCurrentPeriod
                        ? {
                              fill: isLight ? setLightness(0.4, brandColor) : brandColor,
                              fontWeight: 'bold'
                          }
                        : {}
                }
            >
                <tspan x={attrs.x} dy="0.71em">
                    {formatDate(payload.value)}
                </tspan>
            </text>
        )
    }

    renderTick.propTypes = {
        payload: PropTypes.shape({
            value: PropTypes.string.isRequired
        }).isRequired,
        index: PropTypes.any.isRequired,
        verticalAnchor: PropTypes.any.isRequired,
        visibleTicksCount: PropTypes.any.isRequired
    }

    return (
        <StyledCard
            title={t`Production per`}
            className="production"
            delay={800}
            data-testid="prod-card"
            buttons={[
                {
                    // TODO: Style datepicker
                    replace: (
                        <ConfigProvider
                            locale={ANTD_LOCALES[USER_LANGUAGE] || ANTD_LOCALES.en}
                        >
                            <DatePicker
                                picker={PERIODS[period]}
                                value={moment(date)}
                                onChange={(newDate) =>
                                    setDate((newDate || moment()).startOf('day'))
                                }
                                disabled={loading}
                                disabledDate={(current) =>
                                    current > moment().endOf(PERIODS[period])
                                }
                            />
                        </ConfigProvider>
                    )
                }
            ]}
        >
            <ButtonGroup
                disabled={loading}
                buttons={[
                    {
                        content: t`Day`,
                        active: period === 0,
                        onClick: () => setPeriod(0)
                    },
                    {
                        content: t`Week`,
                        active: period === 1,
                        onClick: () => setPeriod(1)
                    },
                    {
                        content: t`Month`,
                        active: period === 2,
                        onClick: () => setPeriod(2)
                    },
                    {
                        content: t`Year`,
                        active: period === 3,
                        onClick: () => setPeriod(3)
                    }
                ]}
            />
            {graphData?.length ? (
                <div className="graph-area">
                    {/* TODO: Swipe support */}
                    {/* TODO: Change limit based on available size */}
                    {/* TODO: Graph responsiveness */}
                    <button
                        type="button"
                        className="arrow"
                        onClick={() => shiftDate('left')}
                        disabled={loading}
                    >
                        <LeftOutlined />
                    </button>
                    <div className="graph-container">
                        <ResponsiveContainer>
                            <BarChart data={graphData}>
                                <Bar
                                    dataKey="resultedYield"
                                    name={t`Production`}
                                    unit={` ${t`kWh`}`}
                                    fill={
                                        isLight
                                            ? setLightness(0.4, brandColor)
                                            : brandColor
                                    }
                                />
                                <CartesianGrid vertical={false} />
                                <XAxis
                                    height={20}
                                    dataKey="groupedDate"
                                    interval="preserveStartEnd"
                                    padding={{ left: 10, right: 10 }}
                                    axisLine={false}
                                    tickLine={false}
                                    tickFormatter={formatDate}
                                    tick={renderTick}
                                />
                                <YAxis
                                    width={65}
                                    tickFormatter={formatNumber}
                                    axisLine={false}
                                    tickLine={false}
                                />
                                <Tooltip
                                    separator=": "
                                    labelFormatter={(label) =>
                                        classNames(
                                            formatDate(label, true),
                                            period === 1 &&
                                                `- ${formatDate(
                                                    moment(label).endOf('week').toDate(),
                                                    true
                                                )} (${t`Week`} ${moment(label).week()})`
                                        )
                                    }
                                    formatter={formatNumber}
                                    cursor={{
                                        fill: brandColor,
                                        fillOpacity: 0.25,
                                        strokeWidth: 0
                                    }}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <button
                        type="button"
                        className="arrow"
                        onClick={() => shiftDate('right')}
                        disabled={loading || !canShiftRight}
                    >
                        <RightOutlined />
                    </button>
                </div>
            ) : (
                <EmptyState height="221px" />
            )}
            <LoadingOverlay loading={loading} />
        </StyledCard>
    )
}

ProdCard.propTypes = {
    customDate: PropTypes.any
}

ProdCard.defaultProps = {
    customDate: undefined
}
