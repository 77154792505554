import styled from 'styled-components'
import Card from '@consumer-dashboard/components/common/Card'

export default styled(Card)`
    display: flex;
    flex-direction: column;
    & > .content {
        display: flex;
        flex: 1;
        flex-direction: column;
        position: relative;
        & > .graph {
            flex: 1;
            margin-top: 8px;
            min-height: 240px;
            max-width: 100%;
        }
    }
`
