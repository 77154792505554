import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import noop from 'lodash/noop'
import { CSSTransition } from 'react-transition-group'
import { StyledImage, StyledMissing } from './Image.styled'

export default function Image({
    src,
    alt,
    className,
    placeholder,
    onLoad,
    onError,
    hideOnMissing,
    ...props
}) {
    const [missing, setMissing] = useState(!src)
    const [loaded, setLoaded] = useState(false)

    const handleLoad = () => {
        setLoaded(true)
        onLoad()
    }

    const handleError = () => {
        setMissing(true)
    }

    useEffect(() => {
        missing && onError()
    }, [missing])

    return missing ? (
        !hideOnMissing && (
            <StyledMissing
                data-testid="missing-image-component"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                className={className}
            >
                {placeholder || alt}
            </StyledMissing>
        )
    ) : (
        <CSSTransition in={loaded} timeout={0} classNames="image">
            <StyledImage
                data-testid="image-component"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                src={src}
                alt={alt}
                className={classNames(className, { loading: !loaded })}
                onLoad={handleLoad}
                onError={handleError}
            />
        </CSSTransition>
    )
}

Image.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
    hideOnMissing: PropTypes.bool
}

Image.defaultProps = {
    src: '',
    alt: '',
    className: '',
    placeholder: '',
    onLoad: noop,
    onError: noop,
    hideOnMissing: false
}
