import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Redux from './redux/redux';
import MainRouter from './components/MainRouter';
import LoginRouter from './components/LoginRouter';
import 'antd/dist/antd.css';
import './app.scss';
window.renderDashboardApp = () => {
    ReactDOM.render(_jsx(Redux, { children: _jsx(Router, { children: _jsx(MainRouter, {}, void 0) }, void 0) }, void 0), document.getElementById('consumer-dashboard-react-app'));
};
window.renderLogin = () => {
    ReactDOM.render(_jsx(LoginRouter, {}, void 0), document.getElementById('consumer-dashboard-react-app'));
};
