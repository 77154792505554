import { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components'
import { setLightness } from 'polished'
import { CSSTransition } from 'react-transition-group'
import SpinLoader from 'generic/components/SpinLoader'

const StyledLoading = styled.div`
    align-items: center;
    background-color: rgba(255, 255, 255, 0.75);
    display: flex;
    border-radius: inherit;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 600ms;
    width: 100%;
    &.loading-enter {
        opacity: 0;
    }
    &.loading-enter-active,
    &.loading-exit {
        opacity: 1;
    }
    &.loading-exit-active {
        opacity: 0;
    }
    &.loading-exit-done {
        display: none;
    }
`

export default function LoadingOverlay({ loading, children }) {
    const { isLight, brandColor } = useContext(ThemeContext) || {}
    const [initialLoading, setInitialLoading] = useState(true)

    useEffect(() => {
        setInitialLoading(false)
    }, [])

    return (
        <CSSTransition in={loading || initialLoading} timeout={600} classNames="loading">
            <StyledLoading data-testid="loading-overlay">
                {children || (
                    <SpinLoader
                        size={36}
                        primaryColor={
                            isLight ? setLightness(0.4, brandColor) : brandColor
                        }
                        secondaryColor="transparent"
                    />
                )}
            </StyledLoading>
        </CSSTransition>
    )
}

LoadingOverlay.propTypes = {
    loading: PropTypes.bool,
    children: PropTypes.node
}

LoadingOverlay.defaultProps = {
    loading: false,
    children: undefined
}
