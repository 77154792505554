import styled from 'styled-components'
import Card from '@consumer-dashboard/components/common/Card'

export default styled(Card)`
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    & > .content {
        align-items: center;
        display: flex;
        flex-direction: column;
        text-align: center;
        & > .magic-link-image {
            height: 150px;
            width: 180px;
        }
        & > .title,
        & > p {
            line-height: 1.5;
        }
        & > .title {
            font-weight: 700;
            margin: 12px 0 20px;
        }
        & > p {
            margin: 0;
            & > .date {
                font-weight: 700;
            }
        }
        & > .log-out-button {
            margin-top: 28px;
            max-width: 300px;
            text-align: center;
            width: 100%;
        }
    }
`
