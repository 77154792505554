import { Select } from 'antd'
import styled from 'styled-components'
import { setLightness, transparentize } from 'polished'
import { defaultColor } from '@consumer-dashboard/components/common/BrandColored'

export default styled(Select)`
    color: ${({ theme: { isLight, brandColor } }) =>
        isLight ? setLightness(0.2, brandColor || defaultColor) : brandColor};
    &.ant-select-focused > .ant-select-selector {
        box-shadow: 0 0 0 2px
            ${({ theme: { isLight, brandColor } }) =>
                isLight
                    ? transparentize(0.8, setLightness(0.2, brandColor || defaultColor))
                    : transparentize(0.8, brandColor || defaultColor)} !important;
    }
    &.ant-select-focused,
    &:not(.ant-select-disabled):hover {
        & > .ant-select-selector {
            border-color: ${({ theme: { isLight, brandColor } }) =>
                isLight
                    ? setLightness(0.2, brandColor || defaultColor)
                    : brandColor} !important;
        }
    }
`
