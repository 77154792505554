// TODO: Tests

import { combineReducers } from 'redux'
import {
    FETCH_MONITORED_SYSTEMS,
    FETCH_MONITORED_SYSTEMS_SUCCEEDED,
    FETCH_MONITORED_SYSTEMS_FAILED,
    FETCH_DASHBOARD_SUCCEEDED,
    FETCH_DASHBOARD_FAILED,
    SHOW_LOADING,
    HIDE_LOADING
} from './types'

export const INITIAL_MONITORED_SYSTEMS = {
    systems: [],
    selectedSystem: null // Duplicate for better reachability
}

export const INITIAL_DASHBOARD = {
    expiration: null,
    systems: {},
    selectedSystem: null // Duplicate for better reachability
}

export const INITIAL_LOADING = true

export const INITIAL_ERROR = {
    error: undefined,
    show404: false,
    retry: undefined
}

const monitoredSystemsReducer = (
    state = INITIAL_MONITORED_SYSTEMS,
    { type, payload }
) => {
    switch (type) {
        case FETCH_MONITORED_SYSTEMS_SUCCEEDED:
            return {
                ...INITIAL_MONITORED_SYSTEMS,
                systems: payload,
                selectedSystem: payload?.[0]?.id
            }
        case FETCH_DASHBOARD_SUCCEEDED: {
            return {
                ...state,
                selectedSystem: payload?.system?.id
            }
        }
        case FETCH_MONITORED_SYSTEMS:
        case FETCH_MONITORED_SYSTEMS_FAILED:
            return INITIAL_MONITORED_SYSTEMS
        default:
            return state
    }
}

const dashboardReducer = (state = INITIAL_DASHBOARD, { type, payload }) => {
    switch (type) {
        case FETCH_DASHBOARD_SUCCEEDED: {
            const { expiration, system } = payload

            return {
                ...state,
                expiration,
                systems: {
                    ...state.systems,
                    [system.id]: system
                },
                selectedSystem: system.id
            }
        }
        case FETCH_DASHBOARD_FAILED:
            return INITIAL_DASHBOARD
        default:
            return state
    }
}

const loadingReducer = (state = INITIAL_LOADING, { type }) => {
    switch (type) {
        case SHOW_LOADING:
            return true
        case HIDE_LOADING:
            return false
        default:
            return state
    }
}

const errorReducer = (state = INITIAL_ERROR, { type, payload }) => {
    switch (type) {
        case FETCH_MONITORED_SYSTEMS_FAILED:
        case FETCH_DASHBOARD_FAILED:
            return {
                ...INITIAL_ERROR,
                error: payload.error,
                show404: payload.error?.status === 404,
                retry: payload.retry
            }
        case FETCH_DASHBOARD_SUCCEEDED:
        case FETCH_MONITORED_SYSTEMS_SUCCEEDED:
            return INITIAL_ERROR
        default:
            return state
    }
}

export const INITIAL_STORE = {
    monitoredSystems: INITIAL_MONITORED_SYSTEMS,
    dashboard: INITIAL_DASHBOARD,
    loading: INITIAL_LOADING,
    error: INITIAL_ERROR
}

export default combineReducers({
    monitoredSystems: monitoredSystemsReducer,
    dashboard: dashboardReducer,
    loading: loadingReducer,
    error: errorReducer
})
