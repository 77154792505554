import { useSelector } from 'react-redux'
import { t } from 'ttag'
import InfoCard from './InfoCard'
import SystemElementsCard from './SystemElementsCard'
import NoData from '@consumer-dashboard/components/common/NoData'
import StyledSystem from './System.styled'
import { getDashboard, getLoading, getError } from '@consumer-dashboard/redux/selectors'

export default function System() {
    const {
        dashboard: { systems, selectedSystem },
        loading,
        error
    } = useSelector((s) => ({
        dashboard: getDashboard(s),
        loading: getLoading(s),
        error: getError(s).error
    }))

    if (!Object.keys(systems).length || loading || error) {
        return null
    }

    const { panels, inverter } = systems[selectedSystem]

    return (
        <StyledSystem className="system page">
            <h1 className="page-title">{t`My system`}</h1>
            {panels || inverter ? (
                <div className="cards">
                    <InfoCard />
                    <SystemElementsCard />
                </div>
            ) : (
                <NoData />
            )}
        </StyledSystem>
    )
}
