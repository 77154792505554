import styled from 'styled-components'

export default styled.div`
    & > .cards {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        & > .kpis {
            flex-basis: calc(50% - 8px);
            & > .reduction-kpis {
                margin-top: 16px;
            }
        }
        & > .recent-production {
            align-self: stretch;
            flex-basis: calc(50% - 8px);
            margin-left: 16px;
            width: calc(50% - 8px);
        }
        & > .production {
            flex-basis: 100%;
            margin-top: 16px;
        }
        & > .production-year {
            margin-top: 16px;
            flex-basis: 50%;
        }
    }
    & > .powered-by {
        align-items: center;
        color: #757575;
        display: flex;
        justify-content: center;
        margin: 16px 0 0;
        & > svg {
            margin-bottom: -2px;
        }
    }
    @media (max-width: 1023px) {
        & > .cards {
            & > .kpis {
                flex-basis: calc(50% - 6px);
                & > .reduction-kpis {
                    margin-top: 12px;
                }
            }
            & > .recent-production {
                flex-basis: calc(50% - 6px);
                margin-left: 12px;
                width: calc(50% - 6px);
            }
            & > .production,
            & > .production-year {
                margin-top: 12px;
            }
        }
        & > .powered-by {
            margin-top: 12px;
        }
    }
    @media (max-width: 767px) {
        & > .cards {
            & > .kpis,
            & > .recent-production,
            & > .production,
            & > .production-year {
                flex-basis: 100%;
            }
            & > .kpis {
                & > .reduction-kpis {
                    margin-top: 8px;
                }
            }
            & > .recent-production {
                margin-left: 0;
                margin-top: 8px;
                width: 100%;
            }
            & > .production,
            & > .production-year {
                margin-top: 8px;
            }
        }
        & > .powered-by {
            margin-top: 8px;
        }
    }
`
