export const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
};
/**
 * Handles service request with fetch API
 *
 * @param {Object} options
 * @param {String} options.url - endpoint to make api request.
 * @param {String} options.method - method for api request. defaults to GET.
 * @param {Object} options.queryObj - key/value pairs for api request queries. (key/value pairs are automatically parsed in the url)
 * @param {Object} options.pathParams - key/value pairs to inject in url path.
 * @param {Object} options.headers - additional api request headers.
 * @param {Object} options.body - body for api request.
 * @param {String} options.credentials - credentials prop for fetch request. can be "omit", "same-origin", or "include". Defaults to "include".
 * @param {Boolean} options.ignorePathParams - if true, url is never parsed for path params
 * @param {String} options.customTimeout - option to give a custom timeout to underlying fetch request (in ms).
 * @param {Boolean} options.shouldSerializeBody - if false, body is not serialized to JSON
 * @param {Boolean} options.shouldDeserializeResponse - if false, body is not deserialized from JSON
 * @returns {Promise}
 */
export function fetchUtil({ url, method = 'GET', queryObj, pathParams, headers, body, credentials = 'include', ignorePathParams = false, customTimeout, signal = null, shouldSerializeBody = true, shouldDeserializeResponse = true }) {
    const { timeout, timeoutPromise, timeoutSignal } = createTimeoutPromiseWithAccessories(customTimeout);
    let endpoint = ignorePathParams ? url : injectPathParamsToUrl(url, pathParams);
    endpoint = appendQueriesToUrl(endpoint, queryObj);
    const headersToSend = {
        ...getCSRFHeaders(endpoint, method),
        ...defaultHeaders,
        ...headers,
        ...getImpersonationHeader(credentials)
    };
    let bodyToSend = (shouldSerializeBody && serializeBody(method, body)) || body;
    if (body instanceof FormData) {
        // let browser figure out content-type
        delete headersToSend['Content-Type'];
        bodyToSend = body;
    }
    const fetchPromise = fetch(endpoint, {
        method,
        headers: headersToSend,
        body: bodyToSend,
        credentials,
        signal: signal || timeoutSignal
    })
        .then((res) => {
        if (res.ok && res.status !== 204) {
            if (shouldDeserializeResponse) {
                return res.json();
            }
            else {
                return Promise.resolve({});
            }
        }
        else if (res.status >= 400 && res.status <= 599 && res.status !== 402) {
            throw new FetchError({
                method,
                endpoint,
                message: res.statusText,
                status: res.status,
                response: res.json()
            });
        }
        else if (res.status === 402) {
            // In three cases we return a status 402. When you try to save a
            // big system, if you publish an online offer, or when a user
            // account is suspended. In the first two cases we require a
            // FetchError, in the last case we want to redirect to
            // /suspended/.
            return res.json().then((data) => {
                if ((data === null || data === void 0 ? void 0 : data.error) === 'user_suspended') {
                    window.location.href = '/suspended/';
                }
                throw new FetchError({
                    method,
                    endpoint,
                    message: res.statusText,
                    status: res.status,
                    response: Promise.resolve(data)
                });
            });
        }
        return Promise.resolve({});
    })
        .finally(() => {
        timeout && clearTimeout(timeout);
    });
    return Promise.race([timeoutPromise, fetchPromise]);
}
function createTimeoutPromiseWithAccessories(timeoutPeriod) {
    let timeoutSignal = null;
    let timeout = null;
    const timeoutPromise = new Promise((_, reject) => {
        if (timeoutPeriod) {
            const controller = new AbortController();
            timeoutSignal = controller.signal;
            timeout = setTimeout(() => {
                controller.abort();
                reject(new FetchError({ message: 'Request timed out' }));
            }, timeoutPeriod);
        }
    });
    return { timeout, timeoutPromise, timeoutSignal };
}
function injectPathParamsToUrl(url = '', paramsObj = {}) {
    let endpoint = url;
    //  pad endpoint with '/' on both sides
    if (!endpoint.startsWith('/'))
        endpoint = '/' + endpoint;
    if (!endpoint.endsWith('/'))
        endpoint += '/';
    return endpoint.replace(/:(.+?)(\/)/g, (_match, key) => {
        const valueToReplace = paramsObj[key];
        if (valueToReplace === undefined ||
            valueToReplace === null ||
            valueToReplace === '') {
            return '';
        }
        else {
            return valueToReplace + '/';
        }
    });
}
function appendQueriesToUrl(url, queryObj) {
    let endpoint = url;
    if (!queryObj)
        return endpoint;
    if (!endpoint.endsWith('?'))
        endpoint += '?';
    return (endpoint +
        Object.entries(queryObj)
            .map(([key, val]) => `${key}=${val === undefined || val === null ? '' : val}`)
            .join('&'));
}
function serializeBody(method, body) {
    if (method === 'GET') {
        return undefined;
    }
    if (!body) {
        return '';
    }
    return JSON.stringify(body);
}
function getImpersonationHeader(credentials) {
    if (['same-origin', 'include'].includes(credentials)) {
        const impersonationIdString = sessionStorage.getItem('impersonate-user');
        if (impersonationIdString) {
            const impersonationId = parseInt(impersonationIdString);
            if (!isNaN(impersonationId)) {
                return { 'X-Impersonate-User': impersonationId };
            }
        }
    }
    return {};
}
export function getCSRFHeaders(endpoint, method) {
    // only include csrf with certain methods and on relative paths
    const csrfToken = getCookie('csrftoken');
    const methods = ['GET', 'HEAD', 'OPTIONS', 'TRACE'];
    if (csrfToken && !methods.includes(method) && endpoint.startsWith('/')) {
        return { 'X-CSRFToken': csrfToken };
    }
}
export function getCookie(name) {
    let cookieValue;
    if (document.cookie) {
        const cookies = document.cookie.split(';');
        cookies.forEach((element) => {
            const [key, value] = element.trim().split('=');
            if (key === name) {
                cookieValue = value;
            }
        });
    }
    return cookieValue;
}
export class FetchError extends Error {
    constructor(params) {
        super(params.message || 'Fetch Error');
        this.name = 'FetchError';
        this.status = params.status;
        this.data = params.data;
        this.method = params.method;
        this.endpoint = params.endpoint;
        this.response = params.response;
        Object.setPrototypeOf(this, FetchError.prototype);
    }
}
FetchError.prototype.name = 'FetchError';
