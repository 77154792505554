import { useSelector } from 'react-redux'
import { t } from 'ttag'
import ContactCard from './ContactCard'
import InstallerCard from './InstallerCard'
import StyledInstaller from './Installer.styled'
import { getDashboard, getLoading, getError } from '@consumer-dashboard/redux/selectors'

export default function Installer() {
    const {
        dashboard: { systems },
        loading,
        error
    } = useSelector((s) => ({
        dashboard: getDashboard(s),
        loading: getLoading(s),
        error: getError(s).error
    }))

    if (!Object.keys(systems).length || loading || error) {
        return null
    }

    return (
        <StyledInstaller className="installer page">
            <h1 className="page-title">{t`My installer`}</h1>
            <div className="cards">
                <ContactCard />
                <InstallerCard />
            </div>
        </StyledInstaller>
    )
}
