import { jsx as _jsx } from "react/jsx-runtime";
import { MONKEY_PLUM } from 'generic/theme/colours';
import styled, { keyframes } from 'styled-components';
const spin = keyframes `
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;
const StyledSpinLoader = styled.div `
    animation: ${spin} 1s linear infinite;
    border-radius: 50%;
    border: ${({ thickness, secondaryColor }) => `${thickness}px solid ${secondaryColor}`};
    border-top: ${({ thickness, primaryColor }) => `${thickness}px solid ${primaryColor}`};
    display: ${({ isLoading }) => (isLoading ? 'flex' : 'none')};
    height: ${({ size }) => `${size}px`};
    min-height: ${({ size }) => `${size}px`};
    min-width: ${({ size }) => `${size}px`};
    width: ${({ size }) => `${size}px`};
`;
/**
 * Loader component used for displaying a loading spinner inside of the button
 * while an async action is being performed.
 */
function SpinLoader({ size = 25, thickness = 2, secondaryColor = 'transparent', primaryColor = MONKEY_PLUM, isLoading = true, 
// Pass all HTML attributes for a styled div element as it's generic
// component. Also, it's needed to pass data attributes used for Testim
// testing.
...props }) {
    return (_jsx(StyledSpinLoader, Object.assign({ size: size, thickness: thickness, secondaryColor: secondaryColor, primaryColor: primaryColor, isLoading: isLoading }, props), void 0));
}
export default SpinLoader;
