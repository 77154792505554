import styled from 'styled-components'
import { setLightness } from 'polished'

export default styled.div`
    align-items: flex-end;
    display: flex;
    user-select: none;
    & > .button {
        background-color: transparent;
        border: none;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        padding: 8px;
        transition: border-color 200ms;
        &:not(:last-child) {
            margin-right: 16px;
        }
        &.active,
        &:not([disabled]):hover {
            border-color: ${({ theme: { isLight, brandColor } }) =>
                isLight ? setLightness(0.4, brandColor) : brandColor};
        }
        &[disabled] {
            cursor: not-allowed;
            background-color: #f5f5f5;
            color: #aaa;
        }
    }
    @media (max-width: 767px) {
        & > .button:not(:last-child) {
            margin-right: 12px;
        }
    }
`
