import styled from 'styled-components'
import Card from '@consumer-dashboard/components/common/Card'

export default styled(Card)`
    & > .content {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        & > h2 {
            color: #454444;
            font-size: 17px;
            font-weight: 400;
            margin: 0;
        }
        & > .address-line-2 {
            margin: 4px 0 12px;
        }
        & > .system-image {
            align-self: center;
            height: 200px;
            object-fit: cover;
            width: 100%;
        }
        & > p,
        & > a {
            font-size: 13px;
            margin: 0;
        }
        & > a {
            margin-top: 4px;
        }
        & > .help-text {
            margin-top: 12px;
        }
    }
`
