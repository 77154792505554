import styled from 'styled-components'

export const StyledImage = styled.img`
    max-height: 100%;
    max-width: 100%;
    opacity: 0;
    transition: all 400ms, opacity 1s;
    &.loading {
        min-height: 33px;
        min-width: 33px;
    }
    &.image-enter {
        opacity: 0;
    }
    &.image-enter-done {
        opacity: 1;
    }
`

export const StyledMissing = styled.span`
    align-items: center;
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    padding: 4px;
    text-align: center;
    transition: min-height 200ms, min-width 200ms;
`

export default {
    StyledImage,
    StyledMissing
}
