import { useSelector } from 'react-redux'
import { t } from 'ttag'
import { Link } from '@consumer-dashboard/components/common/RouterComponents'
import Image from '@consumer-dashboard/components/common/Image'
import StyledCard from './InfoCard.styled'
import { getDashboard } from '@consumer-dashboard/redux/selectors'
import { NAVIGATION } from '@consumer-dashboard/utils/constants'

export default function InfoCard() {
    const { systems, selectedSystem } = useSelector(getDashboard)

    const {
        image,
        consumer: { street, houseNumber, postcode, city }
    } = systems[selectedSystem]

    const addressLine1 = [street, houseNumber].filter((s) => s).join(' ')
    const addressLine2 = [postcode, city].filter((s) => s).join(', ')

    return (
        <StyledCard className="system-info" delay={200} data-testid="system-info-card">
            {!!addressLine1 && (
                <h2 className="address-line-1" data-testid="system-address-line-1">
                    {addressLine1}
                </h2>
            )}
            {!!addressLine2 && (
                <h2 className="address-line-2" data-testid="system-address-line-2">
                    {addressLine2}
                </h2>
            )}
            {image && (
                <Image
                    src={image}
                    alt={t`Satellite image of the system`}
                    className="system-image"
                    placeholder={t`Missing system image`}
                    data-testid="system-image"
                />
            )}
            <p className="help-text">{t`Does this system seem incorrect?`}</p>
            <Link to={NAVIGATION.installer.path} className="help-link brand-link">
                {t`Contact us`} &gt;
            </Link>
        </StyledCard>
    )
}
