import PropTypes from 'prop-types'
import classNames from 'classnames'
import StyledButtonGroup from './ButtonGroup.styled'

export default function ButtonGroup({ buttons, disabled: globalDisabled }) {
    if (!buttons.length) {
        return null
    }

    return (
        <StyledButtonGroup className="button-group" data-testid="button-group">
            {buttons.map(
                ({
                    content,
                    active,
                    className,
                    disabled,
                    onClick,
                    to,
                    href,
                    ...props
                }) => (
                    <button
                        key={content}
                        type="button"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}
                        className={classNames('button', className, { active })}
                        disabled={disabled || globalDisabled}
                        onClick={() => onClick && onClick()}
                        to={disabled ? undefined : to}
                        href={disabled ? undefined : href}
                        data-testid="button-group-button"
                    >
                        {content}
                    </button>
                )
            )}
        </StyledButtonGroup>
    )
}

ButtonGroup.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.shape({ content: PropTypes.node })),
    disabled: PropTypes.bool
}

ButtonGroup.defaultProps = {
    buttons: [],
    disabled: false
}
