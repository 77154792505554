import { t } from 'ttag'
import { Link } from '@consumer-dashboard/components/common/RouterComponents'
import StyledNoData from './NoData.styled'
import Image from './Image'
import { NAVIGATION, PRELOADED_IMAGES } from '@consumer-dashboard/utils/constants'

export default function NoData() {
    return (
        <StyledNoData data-testid="no-data-screen">
            <Image
                src={PRELOADED_IMAGES['no-data']}
                alt={t`No data image`}
                className="no-data-image"
            />
            <h2>{t`No data yet...`}</h2>
            <p className="description">
                {t`Please be patient. We first probably need to install and set your system up.`}
            </p>
            <p className="problems">
                <span>{t`That's not it?`}</span>
                <Link to={NAVIGATION.installer.path} className="brand-link">
                    {t`Contact us`} &gt;
                </Link>
            </p>
        </StyledNoData>
    )
}
