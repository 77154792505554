import { t } from 'ttag'
import enLocale from 'antd/lib/locale-provider/en_GB'
import nlLocale from 'antd/lib/locale-provider/nl_NL'
import esLocale from 'antd/lib/locale-provider/es_ES'
import frLocale from 'antd/lib/locale-provider/fr_FR'
import EnergyIcon from '@consumer-dashboard/svgs/energy-icon'
import SystemIcon from '@consumer-dashboard/svgs/system-icon'
import InstallerIcon from '@consumer-dashboard/svgs/installer-icon'
import AccountIcon from '@consumer-dashboard/svgs/account-icon'
import { getSettings } from 'settings.singleton'

export const STATIC_URL = getSettings().STATIC_URL
export const BASE_PATH = getSettings().CONSUMER_DASHBOARD_BASE_PATH

export const NAVIGATION = {
    energy: {
        name: 'My energy',
        svg: EnergyIcon,
        path: `${BASE_PATH}energy/`
    },
    system: {
        name: 'My system',
        svg: SystemIcon,
        path: `${BASE_PATH}system/`
    },
    installer: {
        name: 'My installer',
        svg: InstallerIcon,
        path: `${BASE_PATH}installer/`
    },
    account: {
        name: 'My account',
        svg: AccountIcon,
        path: `${BASE_PATH}account/`
    }
}

export const TRANSLATED_NAVIGATION = () => ({
    'My energy': t`My energy`,
    'My system': t`My system`,
    'My installer': t`My installer`,
    'My account': t`My account`
})

export const PRELOADED_IMAGES = {
    panel: `${STATIC_URL}images/consumer-dashboard/panel.svg`,
    inverter: `${STATIC_URL}images/consumer-dashboard/inverter.svg`,
    'magic-link': `${STATIC_URL}images/consumer-dashboard/magic-link.png`,
    'email-sent': `${STATIC_URL}images/consumer-dashboard/email-sent.png`,
    'no-data': `${STATIC_URL}images/consumer-dashboard/no-data.png`,
    'maintenance-mode': `${STATIC_URL}images/consumer-dashboard/maintenance-mode.png`
}

export const USER_LANGUAGE = navigator.language || navigator.userLanguage

export const USER_TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

export const GLOBAL_TIME_ZONE = 'UTC'

export const ANTD_LOCALES = {
    en: enLocale,
    en_US: enLocale,
    uk: enLocale,
    en_UK: enLocale,
    nl: nlLocale,
    nl_NL: nlLocale,
    es: esLocale,
    es_ES: esLocale,
    fr: frLocale,
    fr_FR: frLocale
}

export default {
    STATIC_URL,
    NAVIGATION,
    TRANSLATED_NAVIGATION,
    PRELOADED_IMAGES,
    USER_LANGUAGE,
    USER_TIME_ZONE,
    GLOBAL_TIME_ZONE,
    ANTD_LOCALES,
    BASE_PATH
}
