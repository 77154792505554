import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { t } from 'ttag'
import StyledBlankScreen from './BlankScreen.styled'
import { getError } from '@consumer-dashboard/redux/selectors'

// In miliseconds
const REDIRECT_MESSAGE_SCREEN_TIME = 3000

export default function ErrorScreen() {
    const { error, show404, retry } = useSelector(getError)

    const defaultErrorMessage = t`Something went wrong`

    const [errorMessage, setErrorMessage] = useState(defaultErrorMessage)
    const [loggedIn, setLoggedIn] = useState(true)

    useEffect(() => {
        const fetchResponseData = async () => {
            const data = await error?.response

            if (data?.detail === 'Not authenticated') {
                setErrorMessage(t`Not logged in`)
                setLoggedIn(false)
            } else {
                setErrorMessage(data?.detail || error?.message || defaultErrorMessage)
                setLoggedIn(true)
            }
        }
        fetchResponseData()
    }, [error])

    useEffect(() => {
        if (!loggedIn) {
            const timeout = setTimeout(() => {
                window.location = '/consumer-dashboard/login/'
            }, REDIRECT_MESSAGE_SCREEN_TIME)

            return () => clearTimeout(timeout)
        }
    }, [loggedIn])

    if (!error) {
        return null
    }

    return (
        <StyledBlankScreen className="blank-screen" data-testid="error-screen">
            <div className="error">
                {show404 ? (
                    <>
                        <h1 className="status" data-testid="error-404">
                            404
                        </h1>
                        <p>{t`This page doesn't exist`}</p>
                    </>
                ) : (
                    <>
                        <p data-testid="error-message">{errorMessage}</p>
                        {!loggedIn ? (
                            <p data-testid="error-redirecting">{t`Redirecting`}...</p>
                        ) : (
                            retry && (
                                <button
                                    type="button"
                                    className="brand-button"
                                    onClick={retry}
                                    data-testid="error-retry-button"
                                >{t`Retry`}</button>
                            )
                        )}
                    </>
                )}
            </div>
        </StyledBlankScreen>
    )
}
