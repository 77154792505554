export default (
    <svg
        className="account-icon"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.43414 18.8091C7.51247 17.7308 8.975 17.125 10.5 17.125H19.5C21.025 17.125 22.4875 17.7308 23.5659 18.8091C24.6442 19.8875 25.25 21.35 25.25 22.875V25.125C25.25 25.8154 24.6904 26.375 24 26.375C23.3096 26.375 22.75 25.8154 22.75 25.125V22.875C22.75 22.013 22.4076 21.1864 21.7981 20.5769C21.1886 19.9674 20.362 19.625 19.5 19.625H10.5C9.63805 19.625 8.8114 19.9674 8.2019 20.5769C7.59241 21.1864 7.25 22.013 7.25 22.875V25.125C7.25 25.8154 6.69036 26.375 6 26.375C5.30964 26.375 4.75 25.8154 4.75 25.125V22.875C4.75 21.35 5.3558 19.8875 6.43414 18.8091Z"
            fill="#757575"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 6.125C13.2051 6.125 11.75 7.58007 11.75 9.375C11.75 11.1699 13.2051 12.625 15 12.625C16.7949 12.625 18.25 11.1699 18.25 9.375C18.25 7.58007 16.7949 6.125 15 6.125ZM9.25 9.375C9.25 6.19936 11.8244 3.625 15 3.625C18.1756 3.625 20.75 6.19936 20.75 9.375C20.75 12.5506 18.1756 15.125 15 15.125C11.8244 15.125 9.25 12.5506 9.25 9.375Z"
            fill="#757575"
        />
    </svg>
)
