import { useState } from 'react'
import { t } from 'ttag'
import LoginCard from './LoginCard'
import LinkSentCard from './LinkSentCard'
import ExplanationCard from './ExplanationCard'
import HelpCard from './HelpCard'
import StyledLogin from './Login.styled'

export default function Login() {
    const errorMessages = {
        account_problem: t`There is a problem with this account`,
        link_expired: t`This link no longer works...`,
        link_invalid: t`Magic link is invalid`
    }

    const [sent, setSent] = useState(false)
    const [help, setHelp] = useState(false)
    const [changedState, setChangedState] = useState(false)
    const [errorMessage] = useState(window._error_message)
    window._error_message = undefined
    const translatedTitle = errorMessages[errorMessage] || t`Personal dashboard`

    const handleSetSent = (newValue) => {
        setChangedState(true)
        setSent(newValue)
    }

    const handleSetHelp = (newValue) => {
        setChangedState(true)
        setHelp(newValue)
    }

    return (
        <StyledLogin className="login page">
            <h1 className="page-title">{translatedTitle}</h1>
            {!!errorMessage && (
                <h2 className="page-subtitle">{t`Log in again below to open your personal dashboard`}</h2>
            )}
            <div className="cards">
                {sent ? (
                    <LinkSentCard
                        onBack={() => handleSetSent(false)}
                        isHelpOpen={help}
                        setHelp={handleSetHelp}
                        delay={changedState ? 0 : 200}
                    />
                ) : (
                    <LoginCard
                        onLinkSent={() => handleSetSent(true)}
                        isHelpOpen={help}
                        setHelp={handleSetHelp}
                        delay={changedState ? 0 : 200}
                    />
                )}
                {help ? (
                    <HelpCard delay={changedState ? 0 : 400} />
                ) : (
                    <ExplanationCard delay={changedState ? 0 : 400} />
                )}
            </div>
        </StyledLogin>
    )
}
