import { addLocale, useLocale } from 'ttag'
import es from 'i18nConsumerDashboard/es.po.json'
import nl from 'i18nConsumerDashboard/nl.po.json'
import fr from 'i18nConsumerDashboard/fr.po.json'
import de from 'i18nConsumerDashboard/de.po.json'

const availableLocales = []

export function changeLanguage(languageKey) {
    // we only need letter language code
    // so, "en-US" will be converted into "en"
    const locale = languageKey.slice(0, 2)
    const locales = { es, nl, fr, de }

    if (locale !== 'en' && !availableLocales.includes(locale)) {
        // eslint-disable-next-line
        const translationObject = locales[locale]
        availableLocales.push(locale)
        addLocale(locale, translationObject)
    }

    useLocale(locale)
}
