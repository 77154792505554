import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import flattenDeep from 'lodash/flattenDeep'
import identity from 'lodash/identity'
import { PRELOADED_IMAGES } from '@consumer-dashboard/utils/constants'
import { getDashboard } from '@consumer-dashboard/redux/selectors'

export default function ImagesPreloader() {
    const { systems } = useSelector(getDashboard)

    useEffect(() => {
        flattenDeep([
            Object.values(PRELOADED_IMAGES),
            Object.values(systems).map(({ image, installer: { brandLogo } }) => [
                image,
                brandLogo
            ])
        ])
            .filter(identity)
            .forEach((image) => {
                new Image().src = image
            })
    }, [systems])

    return null
}
