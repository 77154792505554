import styled from 'styled-components'
import Card from '@consumer-dashboard/components/common/Card'

export default styled(Card)`
    & > .content {
        display: flex;
        flex-direction: column;
        & > h2 {
            color: #454444;
            font-size: 17px;
            font-weight: 400;
            margin: 0;
            &:not(:first-of-type) {
                margin-top: 40px;
            }
        }
    }
`
