import styled from 'styled-components'
import { lighten, darken, setLightness, setSaturation } from 'polished'

const lighterColor = (color) => lighten(0.1, color)

const extraLighterColor = (color) => lighten(0.2, color)

const darkerColor = (color) => darken(0.1, color)

export default styled.div`
    .brand-link,
    .brand-button,
    .brand-checkbox {
        cursor: pointer;
        flex-shrink: 0;
        transition: all 200ms;
    }
    .brand-link {
        background-color: transparent;
        border: none;
        color: ${({ brandColor, isLight }) => (isLight ? '#828282' : brandColor)};
        cursor: pointer;
        margin: 0;
        padding: 0;
        text-decoration: ${({ brandColor, isLight }) =>
            isLight ? `underline ${brandColor}` : 'none'} !important;
        text-decoration-skip-ink: none !important;
        transition: color 200ms, text-decoration 200ms;
        &:hover,
        &.hover,
        &.hovering {
            color: ${({ brandColor, isLight }) =>
                isLight ? brandColor : extraLighterColor(brandColor)};
            text-decoration: ${({ brandColor, isLight }) =>
                `underline ${
                    isLight ? brandColor : extraLighterColor(brandColor)
                }`} !important;
        }
    }
    .brand-button {
        align-items: center;
        background-color: ${({ brandColor }) => brandColor};
        border: none;
        border-radius: 3px;
        color: ${({ isLight }) => (isLight ? '#333' : '#fff')};
        display: flex;
        font-weight: 700;
        justify-content: center;
        overflow: hidden;
        padding: 12px 20px;
        position: relative;
        text-align: center;
        text-decoration: none !important;
        transition: color 200ms, background-color 200ms, box-shadow 200ms;
        user-select: none;
        i {
            font-size: 1.2em;
        }
        & > :not(:first-child) {
            margin-left: 8px;
        }
        &:hover,
        &.hover,
        &.hovering {
            background-color: ${({ brandColor, isLight }) =>
                isLight ? darkerColor(brandColor) : lighterColor(brandColor)};
        }
        &.simple {
            background-color: transparent;
            box-shadow: 0 0 0 1px ${({ brandColor }) => brandColor} inset;
            color: ${({ brandColor, isLight }) => (isLight ? '#333' : brandColor)};
        }
        &.light {
            background-color: ${({ brandColor }) =>
                setLightness(0.85, setSaturation(0.5, brandColor))};
            color: ${({ brandColor, isLight }) => (isLight ? '#333' : brandColor)};
        }
        &.simple,
        &.light {
            &:hover,
            &.hover,
            &.hovering {
                box-shadow: 0 0 0 2px ${({ brandColor }) => brandColor} inset;
            }
        }
        &[disabled],
        &.disabled {
            &,
            &:hover,
            &.hover,
            &.hovering {
                cursor: not-allowed;
                background-color: #f5f5f5;
                box-shadow: 0 0 4px rgba(0, 0, 0, 0.2) inset;
                color: #aaa;
            }
        }
    }
    .brand-checkbox {
        height: 24px;
        margin: 0;
        position: relative;
        width: 24px;
        &:after {
            align-items: center;
            background-color: #fff;
            border-color: ${({ isLight, brandColor }) =>
                isLight ? '#939393' : brandColor};
            border-style: solid;
            border-width: 2px;
            color: ${({ isLight }) => (isLight ? '#333' : '#fff')};
            content: '';
            display: flex;
            font-family: 'Glyphicons Halflings', sans-serif;
            font-size: 18px;
            height: 100%;
            justify-content: center;
            left: 0;
            line-height: 24px;
            position: absolute;
            text-align: center;
            text-indent: -1px;
            top: 0;
            transition: border-color 200ms, box-shadow 200ms;
            width: 100%;
        }
        &.checked::after,
        &:checked::after,
        &:hover::after,
        &.hover::after {
            box-shadow: 0 0 0 10px ${({ brandColor }) => brandColor} inset;
        }
        &:checked::after,
        &.checked::after {
            border-color: ${({ brandColor }) => brandColor};
            content: '\\e013';
        }
        &:hover::after,
        &.hover::after {
            border-color: ${({ isLight, brandColor }) =>
                isLight ? '#939393' : brandColor};
        }
    }
    .brand-background,
    .brand-background-pseudo::before,
    .brand-background-pseudo::after {
        background-color: ${({ brandColor }) => brandColor};
    }
    .brand-color,
    .brand-color-pseudo::before,
    .brand-color-pseudo::after {
        color: ${({ brandColor }) => brandColor};
    }
    .brand-border-color,
    .brand-border-color-pseudo::before,
    .brand-border-color-pseudo::after {
        border-color: ${({ brandColor }) => brandColor};
    }
    .brand-underline,
    .brand-underline-pseudo::before,
    .brand-underline-pseudo::after {
        text-decoration: underline ${({ brandColor }) => brandColor};
    }
    @media (min-width: 1280px) {
        .hide-on-desktop {
            display: none !important;
        }
    }
    @media (max-width: 1279px) {
        .hide-on-notebook-and-down {
            display: none !important;
        }
    }
    @media (max-width: 1279px) and (min-width: 1024px) {
        .hide-on-notebook {
            display: none !important;
        }
    }
    @media (min-width: 1024px) {
        .hide-on-notebook-and-up {
            display: none !important;
        }
    }
    @media (max-width: 1023px) {
        .hide-on-tablet-and-down {
            display: none !important;
        }
    }
    @media (max-width: 1023px) and (min-width: 768px) {
        .hide-on-tablet {
            display: none !important;
        }
    }
    @media (min-width: 768px) {
        .hide-on-tablet-and-up {
            display: none !important;
        }
    }
    @media (max-width: 767px) {
        .hide-on-mobile {
            display: none !important;
        }
    }
`
