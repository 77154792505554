import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'ttag'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'
import SpinLoader from 'generic/components/SpinLoader'
import StyledBlankScreen from './BlankScreen.styled'
import { getLoading, getDashboard } from '@consumer-dashboard/redux/selectors'
import Image from './Image'
import { hideLoading } from '@consumer-dashboard/redux/actions'

// In miliseconds
const LOADING_LOGO_SCREEN_TIME = 1500
const LOADING_LOGO_ANIMATION_TIME = 400

export default function LoadingScreen() {
    const dispatch = useDispatch()

    const {
        loading,
        dashboard: { systems, selectedSystem }
    } = useSelector((s) => ({
        loading: getLoading(s),
        dashboard: getDashboard(s)
    }))

    const [closeTimeout, setCloseTimeout] = useState(null)
    const [showImage, setShowImage] = useState(false)
    const { brandLogo } = systems[selectedSystem]?.installer || {}

    useEffect(() => () => clearTimeout(closeTimeout), [closeTimeout])

    const delayedClose = () => {
        setShowImage(true)

        setCloseTimeout(
            setTimeout(
                () => dispatch(hideLoading()),
                LOADING_LOGO_ANIMATION_TIME + LOADING_LOGO_SCREEN_TIME
            )
        )
    }

    return (
        <CSSTransition in={loading} timeout={600} classNames="blank">
            <StyledBlankScreen className="blank-screen" data-testid="loading-screen">
                <div className="loading">
                    {!!Object.keys(systems).length && (
                        <Image
                            src={brandLogo}
                            alt={t`Brand logo`}
                            className={classNames('loading-brand', {
                                hidden: !showImage
                            })}
                            onLoad={delayedClose}
                            onError={delayedClose}
                            hideOnMissing
                            data-testid="loading-image"
                        />
                    )}
                    <p
                        className="loading-text"
                        data-testid="loading-text"
                    >{t`Loading...`}</p>
                    <SpinLoader
                        size={36}
                        primaryColor="#000"
                        secondaryColor="transparent"
                    />
                </div>
            </StyledBlankScreen>
        </CSSTransition>
    )
}
