// TODO: Tests

import { fetchUtil } from 'utils/fetchUtil'
import {
    deserializeMonitoredSystems,
    deserializeDashboard,
    deserializeProduction,
    deserializeProductionYears
} from './serializers'
import { momentForRequest } from './moment'
import { dateForRequest } from './utils'
import { mockFetchProduction, mockFetchDashboard, mockFetchProductionYear } from './mock'
import urls from './urls'

// 60 seconds
const MAX_REQUEST_TIME = 60 * 1000

export const fetchMonitoredSystems = () =>
    fetchUtil({
        url: urls['monitored-systems'],
        customTimeout: MAX_REQUEST_TIME
    }).then(deserializeMonitoredSystems)

export const fetchDashboard = (systemId) => {
    if (systemId === 'demo') {
        return mockFetchDashboard()
    }
    return fetchUtil({
        url: urls['dashboard-data'].replace(':systemId', systemId),
        customTimeout: MAX_REQUEST_TIME
    }).then(deserializeDashboard)
}

export const fetchProduction = (
    systemId,
    period = 'day',
    fromDate = new Date(),
    toDate = new Date(),
    signal = null
) => {
    if (systemId === 'demo') {
        return mockFetchProduction(
            period,
            dateForRequest(fromDate),
            dateForRequest(toDate)
        )
    }
    return fetchUtil({
        url: urls['production-data'].replace(':systemId', systemId),
        queryObj: {
            from_date: dateForRequest(fromDate),
            to_date: dateForRequest(toDate),
            resolution: period.toUpperCase()
        },
        customTimeout: MAX_REQUEST_TIME,
        signal
    }).then(deserializeProduction)
}

export const fetchRecentProduction = (systemId) => {
    const toDate = momentForRequest().startOf('day').toDate()
    const fromDate = momentForRequest().add(-2, 'day').startOf('day').toDate()

    if (systemId === 'demo') {
        return mockFetchProduction(
            'day',
            dateForRequest(fromDate),
            dateForRequest(toDate)
        )
    }

    return fetchUtil({
        url: urls['production-data'].replace(':systemId', systemId),
        queryObj: {
            from_date: dateForRequest(fromDate),
            to_date: dateForRequest(toDate),
            resolution: 'DAY'
        },
        customTimeout: MAX_REQUEST_TIME
    }).then(deserializeProduction)
}

export const fetchProductionYear = (systemId) => {
    if (systemId === 'demo') {
        return mockFetchProductionYear()
    }

    return fetchUtil({
        url: urls['performance-data'].replace(':systemId', systemId),
        customTimeout: MAX_REQUEST_TIME
    }).then(deserializeProductionYears)
}

export const sendMagicLink = (email) =>
    fetchUtil({
        method: 'POST',
        url: urls['send-magic-link'],
        body: { email },
        customTimeout: MAX_REQUEST_TIME
    })

export const logout = () =>
    fetchUtil({
        url: urls.logout,
        customTimeout: MAX_REQUEST_TIME
    })

export default {
    fetchMonitoredSystems,
    fetchDashboard,
    fetchProduction,
    fetchRecentProduction,
    fetchProductionYear,
    sendMagicLink,
    logout
}
