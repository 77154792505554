import PropTypes from 'prop-types'
import {
    Link as RouterLink,
    NavLink as RouterNavLink,
    useLocation
} from 'react-router-dom'

export function Link({ to, LinkComponent, ...props }) {
    const searchLocation = useLocation().search || ''
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <LinkComponent {...props} to={to + searchLocation} />
}

Link.propTypes = {
    to: PropTypes.string.isRequired,
    LinkComponent: PropTypes.oneOf([RouterLink, RouterNavLink])
}

Link.defaultProps = {
    LinkComponent: RouterLink
}

export function NavLink(props) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Link {...props} LinkComponent={RouterNavLink} />
}

export default {
    Link,
    NavLink
}
