import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { t } from 'ttag'
import classNames from 'classnames'
import StyledCard from './ProdYearCard.styled'
import StyledSelect from '@consumer-dashboard/components/common/Select.styled'
import StyledDropdown from '@consumer-dashboard/components/common/Dropdown.styled'
import LoadingOverlay from '@consumer-dashboard/components/common/LoadingOverlay'
import { fetchProductionYear } from '@consumer-dashboard/utils/api'
import { getDashboard } from '@consumer-dashboard/redux/selectors'
import { createFormatters } from 'generic/generic.utils'
import { USER_LANGUAGE } from '@consumer-dashboard/utils/constants'

export default function ProdYearCard() {
    const { selectedSystem } = useSelector(getDashboard)
    const [selectedYear, setSelectedYear] = useState()
    const [productionYear, setProductionYear] = useState()
    const [loading, setLoading] = useState(false)
    const { formatNumber, formatInteger } = createFormatters(USER_LANGUAGE)
    const { resultedYield, guaranteedYield } = productionYear?.[selectedYear] || {}
    const resultedPct = (resultedYield / (guaranteedYield || 1)) * 100
    const guaranteedPct = resultedPct > 100 ? 10000 / resultedPct : 100
    const formattedResulted = formatInteger(resultedYield)
    const formattedResultedPct = formatNumber(resultedPct)
    const formattedGuaranteed = formatInteger(guaranteedYield)
    const isEmpty = productionYear && !productionYear.length

    useEffect(() => {
        setLoading(true)

        fetchProductionYear(selectedSystem)
            .then((data) => {
                setProductionYear(data)
                data?.length && setSelectedYear(0)
            })
            .catch(() => setProductionYear(null))
            .finally(() => setLoading(false))
    }, [selectedSystem])

    return (
        <StyledCard
            title={t`Results past years`}
            className="production-year"
            delay={1000}
            data-testid="prod-year-card"
        >
            <StyledSelect
                value={selectedYear}
                onChange={setSelectedYear}
                size="large"
                options={productionYear?.map(({ year }, index) => ({
                    label: year,
                    value: index
                }))}
                dropdownRender={(children) => <StyledDropdown>{children}</StyledDropdown>}
                disabled={isEmpty}
            />
            {isEmpty ? (
                <p className="message">
                    {t`Once a full year is finished it will show up here`}
                </p>
            ) : (
                <>
                    <p className="total">
                        {formattedResulted} {t`kWh`}
                    </p>
                    <p className="description">
                        {t`${formattedResultedPct}% of the guaranteed ${formattedGuaranteed} kWh`}
                    </p>
                    <div className="visual">
                        <div
                            className="value"
                            style={{ width: `${resultedPct > 100 ? 100 : resultedPct}%` }}
                        />
                        <div
                            className="guaranteed"
                            style={{ left: `calc(${guaranteedPct}% - 2px)` }}
                        >
                            <p
                                className={classNames({
                                    // Those classes align the text to the left/right
                                    // and prevent overflowing when the percentage is
                                    // lower than 7% and higher than 94%
                                    start: guaranteedPct < 7,
                                    end: guaranteedPct > 94
                                })}
                            >{t`Guaranteed`}</p>
                        </div>
                    </div>
                    <p className="contact">
                        {t`In case the yield is lower than the result, we will contact you.`}
                    </p>
                </>
            )}

            <LoadingOverlay loading={loading} />
        </StyledCard>
    )
}
