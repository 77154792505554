// TODO: Tests

export const getMonitoredSystems = (state) => state.monitoredSystems

export const getDashboard = (state) => state.dashboard

export const getLoading = (state) => state.loading

export const getError = (state) => state.error

export default {
    getMonitoredSystems,
    getDashboard,
    getLoading,
    getError
}
