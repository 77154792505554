export default (
    <svg
        className="car-kpi-icon"
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.17497 32.2424H10.4398C10.8496 34.5855 12.8938 36.3728 15.353 36.3728C17.8121 36.3728 19.8546 34.5839 20.2644 32.2424H29.9932C30.403 34.5855 32.4472 36.3728 34.9046 36.3728C37.3637 36.3728 39.408 34.5839 39.8178 32.2424H44.4369C45.3674 32.2424 46.1233 31.4865 46.1233 30.556V25.915C46.1233 23.5433 44.195 21.615 41.8233 21.615H36.6934C33.5288 18.5932 32.0356 14.6258 27.408 14.6258H17.0458C14.6926 14.6258 12.5274 15.8083 11.2559 17.7886L8.80018 21.6149H5.73476C5.25939 21.6149 4.87476 21.9996 4.87476 22.4749V27.9441C4.87476 30.3141 6.80472 32.2424 9.17476 32.2424L9.17497 32.2424ZM15.3528 34.6544C13.5522 34.6544 12.0874 33.1931 12.0824 31.3942C12.0824 31.3908 12.0841 31.3875 12.0841 31.3824C12.0841 31.3791 12.0824 31.3757 12.0824 31.3707C12.0891 29.5717 13.5538 28.1104 15.3528 28.1104C17.1568 28.1104 18.6231 29.5785 18.6231 31.3824C18.6248 33.1864 17.1568 34.6545 15.3528 34.6545V34.6544ZM34.9062 34.6544C33.1022 34.6544 31.6358 33.1864 31.6358 31.3841C31.6358 29.5801 33.1022 28.112 34.9062 28.112C36.7085 28.112 38.1749 29.5767 38.1782 31.3774C38.1782 31.379 38.1765 31.3807 38.1765 31.3824C38.1765 31.3841 38.1782 31.3858 38.1782 31.3874C38.1749 33.1897 36.7085 34.6544 34.9062 34.6544V34.6544ZM34.3955 21.6151H23.4589V16.3459H27.4079C30.8798 16.3459 32.0188 19.3408 34.3954 21.6151H34.3955ZM12.7042 18.7176C13.6583 17.2328 15.2808 16.3459 17.0463 16.3459H21.7411V21.6152H10.8432L12.7042 18.7176ZM6.59519 23.335H41.8251C43.2478 23.335 44.4051 24.4923 44.4051 25.915L44.4387 30.5242H39.8196C39.4098 28.181 37.3656 26.3921 34.9064 26.3921C32.4473 26.3921 30.4048 28.181 29.995 30.5242H20.2662C19.8564 28.181 17.8122 26.3921 15.3548 26.3921C12.8957 26.3921 10.8514 28.181 10.4416 30.5242H9.17511C7.75241 30.5242 6.59511 29.3669 6.59511 27.9442L6.59519 23.335Z"
            fill="#757575"
        />
    </svg>
)
