import styled from 'styled-components'

export default styled.header`
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 4px 12px;
    position: sticky;
    top: 0;
    user-select: none;
    width: 100%;
    z-index: 1000;
    & > .account {
        align-items: center;
        color: #303030;
        border-radius: 3px;
        display: flex;
        padding: 8px 12px;
        text-decoration: none !important;
        transition: background-color 200ms;
        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
        & > :not(:first-child) {
            margin-left: 8px;
        }
        & > svg {
            height: 25px;
            width: 25px;
            path {
                fill: #454444;
            }
        }
    }
    & > .sidebar-toggler {
        background-color: transparent;
        border: none;
        border-radius: 3px;
        line-height: 1;
        padding: 12px;
        transition: background-color 200ms;
        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
        & > svg {
            height: 16px;
            width: 19px;
            line {
                stroke: #000;
            }
        }
    }
    & > .sidebar {
        display: none;
        height: 100vh;
        left: 0;
        overflow-y: auto;
        position: fixed;
        top: 0;
        width: 100vw;
        & > .overlay {
            background-color: rgba(0, 0, 0, 0.5);
            height: 100vh;
            opacity: 0;
            position: fixed;
            transition: opacity 300ms;
            width: 100vw;
        }
        & > .menu {
            background-color: #454444;
            display: flex;
            flex-direction: column;
            min-height: 100%;
            left: 0;
            max-width: 100%;
            padding: 0 0 12px;
            position: absolute;
            top: 0;
            transform: translateX(-100%);
            transition: transform 300ms;
            width: 200px;
            & > .sidebar-header {
                background-color: inherit;
                padding: 12px;
                position: sticky;
                text-align: right;
                top: 0;
                & > .close-button {
                    background-color: transparent;
                    border: none;
                    border-radius: 3px;
                    cursor: pointer;
                    /* SVG already has 6px padding */
                    padding: 6px;
                    transition: background-color 200ms;
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.1);
                    }
                    & > svg {
                        height: 24px;
                        opacity: 1;
                        width: 24px;
                        path {
                            stroke: #fff;
                        }
                    }
                }
            }
            & > a {
                color: #fff;
                font-size: 19px;
                padding: 12px 32px;
                text-decoration: none;
                transition: background-color 200ms;
                &.active,
                &:hover {
                    background-color: rgba(255, 255, 255, 0.1);
                }
            }
        }
        &.sidebar-enter {
            display: block;
        }
        &.sidebar-enter-active,
        &.sidebar-enter-done,
        &.sidebar-exit {
            display: block;
            & > .overlay {
                opacity: 1;
            }
            & > .menu {
                transform: none;
            }
        }
        &.sidebar-exit-active {
            & > .overlay {
                opacity: 0;
            }
            & > .menu {
                transform: translateX(-100%);
            }
        }
    }
`
