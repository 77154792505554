import { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { t, jt } from 'ttag'
import StyledCard from './LogoutCard.styled'
import { getDashboard } from '@consumer-dashboard/redux/selectors'
import Image from '@consumer-dashboard/components/common/Image'
import { USER_LANGUAGE, PRELOADED_IMAGES } from '@consumer-dashboard/utils/constants'
import { logout } from '@consumer-dashboard/utils/api'
import LoadingOverlay from '@consumer-dashboard/components/common/LoadingOverlay'

export default function LogoutCard({ delay }) {
    const expiration = useSelector((s) => getDashboard(s).expiration)
    const [loading, setLoading] = useState(false)
    const expirationDate = new Date(expiration)

    const translatedExpiration = (
        <span
            key="link-expiration"
            className="date"
            title={expirationDate.toLocaleDateString(USER_LANGUAGE, {
                day: 'numeric',
                weekday: 'long',
                month: 'long',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            })}
        >
            {expirationDate.toLocaleDateString(USER_LANGUAGE, {
                day: 'numeric',
                month: 'long'
            })}
        </span>
    )

    const onLogoutClick = () => {
        setLoading(true)

        logout()
            .then(() => {
                setLoading(false)
                window.location = '/login/'
            })
            .catch(() => setLoading(false))
    }

    return (
        <StyledCard className="log-out" delay={delay} data-testid="logout-card">
            <Image
                src={PRELOADED_IMAGES['magic-link']}
                alt={t`Magic link image`}
                className="magic-link-image"
            />
            <p className="title">{t`You're safely logged in with a magic link!`}</p>
            <p data-testid="expiration-text">
                {jt`This will work until ${translatedExpiration}, then you will have to log in again.`}
            </p>
            <button
                type="button"
                className="log-out-button brand-button"
                onClick={onLogoutClick}
                data-testid="logout-button"
            >{t`Log out`}</button>
            <LoadingOverlay loading={loading} />
        </StyledCard>
    )
}

LogoutCard.propTypes = {
    delay: PropTypes.number
}

LogoutCard.defaultProps = {
    delay: 0
}
