import { useSelector } from 'react-redux'
import { t } from 'ttag'
import AccountSelectCard from './AccountSelectCard'
import LogoutCard from './LogoutCard'
import ExplanationCard from './ExplanationCard'
import StyledAccount from './Account.styled'
import { getDashboard, getLoading, getError } from '@consumer-dashboard/redux/selectors'

export default function Account() {
    const {
        dashboard: { systems },
        loading,
        error
    } = useSelector((s) => ({
        dashboard: getDashboard(s),
        loading: getLoading(s),
        error: getError(s).error
    })) || { dashboard: {} }

    if (!Object.keys(systems).length || loading || error) {
        return null
    }

    return (
        <StyledAccount className="account page">
            <h1 className="page-title">{t`My account`}</h1>
            <div className="cards">
                <div className="card-group">
                    <AccountSelectCard delay={200} />
                    <LogoutCard delay={200} />
                </div>
                <ExplanationCard full delay={400} />
            </div>
        </StyledAccount>
    )
}
