import { useSelector } from 'react-redux'
import { t } from 'ttag'
import identity from 'lodash/identity'
import Image from '@consumer-dashboard/components/common/Image'
import StyledCard from './InstallerCard.styled'
import { getDashboard } from '@consumer-dashboard/redux/selectors'
import { extractHostname, extractPhoneNumber } from '@consumer-dashboard/utils/utils'

export default function InstallerCard() {
    const { systems, selectedSystem } = useSelector(getDashboard)

    const {
        brandLogo,
        name,
        street,
        postcode,
        city,
        country,
        phone,
        email,
        url
    } = systems[selectedSystem].installer

    const addressLine1 = street
    const addressLine2 = [[postcode, city].filter(identity).join(' '), country]
        .filter(identity)
        .join(', ')

    return (
        <StyledCard className="installer" delay={600} data-testid="installer-card">
            <Image
                src={brandLogo}
                alt={t`${name} logo`}
                className="installer-image"
                hideOnMissing
                data-testid="installer-image"
            />
            {(!!name || !!addressLine1 || !!addressLine2) && (
                <section className="address" data-testid="installer-address">
                    {!!name && (
                        <p className="name" data-testid="installer-name">
                            {name}
                        </p>
                    )}
                    {!!addressLine1 && (
                        <p data-testid="installer-address-line-1">{addressLine1}</p>
                    )}
                    {!!addressLine2 && (
                        <p data-testid="installer-address-line-2">{addressLine2}</p>
                    )}
                </section>
            )}
            {(phone || email || url) && (
                <section className="contact">
                    {!!phone && (
                        <a
                            href={`tel:${extractPhoneNumber(phone)}`}
                            className="brand-link"
                            data-testid="installer-phone"
                        >
                            {phone}
                        </a>
                    )}
                    {!!email && (
                        <a
                            href={`mailto:${email}`}
                            className="brand-link"
                            data-testid="installer-email"
                        >
                            {email}
                        </a>
                    )}
                    {!!url && (
                        <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="brand-link"
                            data-testid="installer-url"
                        >
                            {extractHostname(url)}
                        </a>
                    )}
                </section>
            )}
        </StyledCard>
    )
}
