import { createStore, applyMiddleware, compose } from 'redux'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import reduxThunk from 'redux-thunk'
import reducers from './reducers'

export default function Redux({ children, initialState }) {
    const store = createStore(
        reducers,
        initialState,
        compose(
            applyMiddleware(reduxThunk),
            window.__REDUX_DEVTOOLS_EXTENSION__
                ? window.__REDUX_DEVTOOLS_EXTENSION__()
                : (f) => f
        )
    )
    return <Provider store={store}>{children}</Provider>
}

Redux.propTypes = {
    children: PropTypes.node,
    initialState: PropTypes.object
}

Redux.defaultProps = {
    children: null,
    initialState: {}
}
