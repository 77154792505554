export default (
    <svg
        className="leaf-kpi-icon"
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.2364 11.6638L41.2718 12.4161C41.2996 13.0085 41.8575 26.983 33.5083 34.2181C28.6722 38.4089 21.4084 38.0336 17.0241 33.4726L10.331 38.8271C9.9644 39.1203 9.4295 39.0609 9.13624 38.6943C8.84299 38.3278 8.90242 37.7929 9.26899 37.4996L15.9347 32.1671C12.3481 27.1955 13.0912 20.1957 17.8121 16.1048C26.161 8.87001 39.9144 11.41 40.4968 11.5217L41.2364 11.6638ZM21.6546 29.8972L18.7089 32.4498L18.7089 32.4498C22.4177 35.993 28.3019 36.2003 32.2564 32.7735C38.8381 27.0701 39.3573 16.2571 39.3716 13.2794C36.4211 12.8686 25.6455 11.8454 19.0636 17.549C15.1091 20.9758 14.4774 26.83 17.4572 31.0029L20.4028 28.4503L20.6197 19.2376C20.6319 18.7097 21.0707 18.2918 21.5986 18.3041C22.1276 18.3174 22.5444 18.7551 22.5322 19.283L22.3558 26.7582L27.2125 22.5496L27.3488 16.7644C27.361 16.2365 27.7997 15.8186 28.3277 15.8309C28.8545 15.8441 29.2735 16.2819 29.2613 16.8098L29.1661 20.8586L36.1645 14.7941C36.563 14.4488 37.1664 14.4909 37.5127 14.8905C37.858 15.289 37.8159 15.8923 37.4163 16.2386L30.4179 22.3031L34.439 22.7849C34.9634 22.8479 35.3382 23.3239 35.2752 23.8483C35.2123 24.3726 34.7363 24.7474 34.2119 24.6844L28.4652 23.9952L23.6085 28.2038L31.0326 29.0948C31.5569 29.1577 31.9317 29.6337 31.8688 30.1581C31.8058 30.6824 31.3298 31.0572 30.8055 30.9943L21.6546 29.8972Z"
            fill="#757575"
        />
    </svg>
)
