import styled from 'styled-components'

export default styled.header`
    align-items: stretch;
    background-color: #fff;
    border-top: 1px solid #e0e0e0;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    left: 0;
    overflow-x: auto;
    padding: 4px 0;
    position: sticky;
    user-select: none;
    width: 100%;
    z-index: 1000;
    & > .bottom-bar-button {
        align-items: center;
        background-color: transparent;
        border: none;
        color: #303030;
        display: flex;
        flex-direction: column;
        font-size: 10px;
        justify-content: flex-end;
        opacity: 0.5;
        padding: 8px 12px;
        text-align: center;
        text-decoration: none !important;
        transition: opacity 200ms;
        & > svg {
            height: 30px;
            width: 30px;
        }
        &.active,
        &:hover {
            opacity: 1;
        }
        &:not(:first-child) {
            margin-left: 8px;
        }
    }
    @media (max-width: 767px) {
        padding: 0;
        & > .bottom-bar-button {
            padding: 4px;
            &:not(:first-child) {
                margin-left: 0;
            }
        }
    }
`
