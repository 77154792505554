import moment from './moment'
import {
    deserializeDashboard,
    deserializeProduction,
    deserializeProductionYears
} from './serializers'

const MOCK_SYSTEM = {
    id: 'demo',
    image:
        'https://storage.googleapis.com/solarmonkey-static-production/demo_dashboard/demo_dashboard_design_image_2.png',
    full_name: 'Demo System',
    is_guarantee: true,
    language: 'nl',
    kwh_price: 0.23,
    domain: 'mijn.solar',
    installer: {
        email: 'info@solarmonkey.nl',
        name: 'Demo',
        street: 'Molengraaffsingel 1',
        postcode: '2629 JD',
        city: 'Delft',
        country: 'NL',
        url: 'https://www.solarmonkey.com',
        phone: '+ 31 15 203 6004',
        brand_logo:
            'https://storage.googleapis.com/solarmonkey-static-production/demo_dashboard/installer_logo_demo.png',
        brand_color: '#c3a536'
    },
    consumer: {
        street: 'Zusterlaan',
        house_number: '20',
        postcode: '2611 MN',
        city: 'Delft',
        country: 'NL'
    },
    panels: {
        summary: '18x325 WP',
        model: 'AS-M1206B 325Wp',
        specsheet: '',
        description: ''
    },
    inverters: {
        model: 'SolarEdge SE2200-ER-01',
        specsheet: '',
        description: ''
    },
    energy: {
        total_production: 6294,
        total_saved: 1447,
        reduction_co2: 2832,
        reduction_km: 21788,
        reduction_flights: 46
    }
}

const createMockPromise = (data) =>
    new Promise((resolve, _reject) => {
        setTimeout(() => {
            resolve(data)
        }, 1000)
    })

const enumerateBetweenDates = (period, startDate, endDate) => {
    const currDate = moment(endDate).startOf(period).startOf('day')
    const firstDate = moment(startDate).startOf(period).startOf('day')
    const dates = period === 'day' ? [] : [currDate.clone()]

    while (currDate.add(-1, period).diff(firstDate) >= 0) {
        dates.push(currDate.clone())
    }

    return dates.reverse()
}

const getResultedYieldForDate = (date) =>
    ({
        0: 0,
        1: 2,
        2: 4,
        3: 6,
        4: 8,
        5: 10,
        6: 12,
        7: 14,
        8: 10,
        9: 6,
        10: 4,
        11: 2
    }[date.getMonth()] + (date.getDate() % 2 === 0 ? 12 : 11.55))

const getResultedYieldForYear = (date) => {
    const yearlyProduction = MOCK_SYSTEM.energy.total_production / 3
    return date.getFullYear() % 2 === 0 ? yearlyProduction + 300 : yearlyProduction
}

const getResultedYield = (period, date) =>
    period === 'day'
        ? getResultedYieldForDate(date)
        : period === 'week'
        ? 7 * getResultedYieldForDate(date)
        : period === 'month'
        ? 30 * getResultedYieldForDate(date)
        : getResultedYieldForYear(date)

export const mockFetchDashboard = () => {
    const expirationDate = new Date()
    expirationDate.setMonth(expirationDate.getMonth() + 1)

    return createMockPromise({
        expiration: expirationDate.toISOString(),
        system: MOCK_SYSTEM
    }).then(deserializeDashboard)
}

export const mockFetchProduction = (period, fromDate, toDate) => {
    let dateRange
    if (period === 'year') {
        const currentDate = new Date()
        dateRange = enumerateBetweenDates(
            period,
            new Date(currentDate.getFullYear() - 2, 0, 1),
            currentDate
        )
    } else {
        dateRange = enumerateBetweenDates(period, fromDate, toDate)
    }

    const results = dateRange.map((moment) => ({
        grouped_date: moment.format('YYYY-MM-DD'),
        resulted_yield: getResultedYield(period, moment.toDate())
    }))

    return createMockPromise({
        count: results.length,
        next: null,
        previous: null,
        results
    }).then(deserializeProduction)
}

export const mockFetchProductionYear = () => {
    const currDate = moment(new Date()).startOf('day')
    const startDate = currDate.clone().add(-1, 'year')
    const resultedYield = getResultedYieldForYear(new Date())
    return createMockPromise([
        {
            year: `Jaar 1 (${startDate.format('YYYY-MM-DD')} – ${currDate.format(
                'YYYY-MM-DD'
            )})`,
            guaranteed_yield: resultedYield - 400,
            resulted_yield: resultedYield
        }
    ]).then(deserializeProductionYears)
}
