import styled from 'styled-components'

export default styled.div`
    color: #303030;
    font-size: 13px;
    & > .page {
        margin: 0 auto;
        max-width: 100%;
        padding: 60px 12px 16px;
        width: 1024px;
        & > .page-title,
        & > .page-subtitle {
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            margin: 0 0 40px;
        }
        & > .page-title {
            font-size: 48px;
        }
        & > .page-subtitle {
            font-size: 27px;
        }
    }
    @media (max-width: 1023px) {
        & > .page {
            padding: 32px 12px 12px;
            & > .page-title,
            & > .page-subtitle {
                margin-bottom: 32px;
                text-align: center;
            }
            & > .page-title {
                font-size: 42px;
            }
            & > .page-subtitle {
                font-size: 24px;
            }
        }
    }
    @media (max-width: 767px) {
        & > .page {
            padding: 8px;
            & > .page-title,
            & > .page-subtitle {
                font-family: inherit;
            }
            & > .page-title {
                background-color: #fff;
                color: #757575;
                font-size: 17px;
                margin: -8px -8px 8px;
                padding: 20px;
            }
            & > .page-subtitle {
                font-size: 14px;
                margin: 16px 0;
            }
        }
    }
`
