// TODO: Tests

export const deserializeMonitoredSystems = (systems) =>
    systems?.map(deserializeMonitoredSystem)

export const deserializeDashboard = (dashboard) =>
    dashboard && {
        expiration: dashboard.expiration,
        system: deserializeSystem(dashboard.system)
    }

export const deserializeProduction = (production) =>
    production && {
        count: production.count,
        next: production.next,
        previous: production.previous,
        results: production.results.map(deserializeProuductionResult)
    }

export const deserializeProductionYears = (productionYears) =>
    productionYears?.map(deserializeProductionYear)

const deserializeMonitoredSystem = (system) =>
    system && { id: system.id, fullName: system.full_name }

const deserializeSystem = (system) =>
    system && {
        id: system.id,
        image: system.image,
        fullName: system.full_name,
        isGuarantee: system.is_guarantee,
        language: system.language,
        kwhPrice: system.kwh_price,
        domain: system.domain,
        installer: deserializeInstaller(system.installer),
        consumer: deserializeConsumer(system.consumer),
        panels: deserializePanels(system.panels),
        inverter: deserializeInverter(system.inverters),
        energy: deserializeEnergy(system.energy)
    }

const deserializeInstaller = (installer) =>
    installer && {
        email: installer.email,
        url: installer.url,
        phone: installer.phone,
        brandLogo: installer.brand_logo,
        brandColor: installer.brand_color,
        name: installer.name,
        street: installer.street,
        postcode: installer.postcode,
        city: installer.city,
        country: installer.country
    }

const deserializeConsumer = (consumer) =>
    consumer && {
        street: consumer.street,
        houseNumber: consumer.house_number,
        postcode: consumer.postcode,
        city: consumer.city,
        country: consumer.country
    }

const deserializePanels = (panels) =>
    panels && {
        summary: panels.summary,
        model: panels.model,
        specsheet: panels.specsheet,
        description: panels.description
    }

const deserializeInverter = (inverter) =>
    inverter && {
        model: inverter.model,
        specsheet: inverter.specsheet,
        description: inverter.description
    }

const deserializeEnergy = (energy) =>
    energy && {
        totalProduction: energy.total_production,
        totalSaved: energy.total_saved,
        reductionCo2: energy.reduction_co2,
        reductionKm: energy.reduction_km,
        reductionFlights: energy.reduction_flights
    }

const deserializeProuductionResult = (result) =>
    result && {
        resultedYield: result.resulted_yield,
        groupedDate: result.grouped_date
    }

const deserializeProductionYear = (productionYear) =>
    productionYear && {
        expectedYield: productionYear.expected_yield,
        guaranteedYield: productionYear.guaranteed_yield,
        resultedYield: productionYear.resulted_yield,
        year: productionYear.year
    }

export default {
    deserializeMonitoredSystems,
    deserializeDashboard,
    deserializeProduction,
    deserializeProductionYears
}
