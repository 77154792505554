import styled from 'styled-components'

export const StyledBlankScreen = styled.div`
    align-items: center;
    background-color: #f2f2f2;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 600ms !important;
    width: 100%;
    z-index: 2000;
    &.blank-enter {
        opacity: 0;
    }
    &.blank-enter-active,
    &.blank-exit {
        opacity: 1;
    }
    &.blank-exit-active {
        opacity: 0;
    }
    &.blank-exit-done {
        display: none;
    }
    & > .error {
        align-items: center;
        display: flex;
        flex-direction: column;
        h1,
        p {
            margin: 0;
            &:not(:first-of-type) {
                margin-top: 16px;
            }
        }
        .status {
            font-size: 82px;
        }
        .brand-button {
            margin-top: 8px;
        }
    }
    & > .loading {
        align-items: center;
        display: flex;
        flex-direction: column;
        & > .loading-text {
            color: #000;
            font-family: 'Montserrat', sans-serif;
            font-weight: 500;
            font-size: 18px;
            margin: 0 0 20px;
            transition: margin 400ms;
        }
        & > .loading-brand {
            background-color: #fff;
            border: 16px solid #fff;
            border-radius: 5px;
            font-family: 'Montserrat', sans-serif;
            font-size: 20px;
            font-weight: bold;
            max-height: 200px;
            max-width: 300px;
            transition: max-height 400ms, transform 400ms;
            &.hidden {
                &,
                & + .loading {
                    display: none !important;
                }
            }
            &.image-enter {
                max-height: 0;
                transform: translateY(100%) scale(0);
            }
            &:not(.hidden) + .loading-text {
                margin-top: 32px;
            }
        }
    }
`

export default StyledBlankScreen
