import styled from 'styled-components'
import Card from '@consumer-dashboard/components/common/Card'

export default styled(Card)`
    & > .content {
        position: relative;
        & > .ant-select {
            margin-top: 4px;
            max-width: 300px;
            width: 100%;
        }
        & > .total {
            font-family: 'Montserrat', sans-serif;
            font-weight: 500;
            font-size: 30px;
            margin: 32px 0 0;
        }
        & > .description,
        & > .contact {
            color: #757575;
        }
        & > .description {
            margin: 4px 0 0;
        }
        & > .visual {
            background-color: #f2f2f2;
            height: 35px;
            margin: 20px 0 40px;
            position: relative;
            width: 100%;
            & > .value {
                background-color: #93c456;
                height: 100%;
                transition: color 200ms, width 200ms;
            }
            & > .guaranteed {
                background-color: #000;
                height: calc(100% + 12px);
                position: absolute;
                top: -6px;
                transition: left 200ms;
                width: 2px;
                & > p {
                    font-size: 12px;
                    left: 50%;
                    margin: 0;
                    position: absolute;
                    text-align: center;
                    top: calc(100% + 4px);
                    transform: translateX(-50%);
                    width: 100px;
                    &.start {
                        text-align: left;
                        transform: none;
                    }
                    &.end {
                        left: initial;
                        right: 0;
                        text-align: right;
                        transform: none;
                    }
                }
            }
        }
        & > .contact {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            margin: 0;
            & > :not(:first-child) {
                margin-top: 4px;
            }
        }
        & > .message {
            margin: 16px 0 0;
        }
    }
`
