import styled from 'styled-components'

export default styled.section`
    background-color: #fff;
    border-radius: 5px;
    flex-basis: 50%;
    opacity: 1;
    padding: 20px;
    transform: none;
    transition: all 400ms;
    &.card-enter {
        opacity: 0;
        transform: ${({ animateFrom }) => {
            switch (animateFrom) {
                case 'top':
                    return 'translateY(-50px)'
                case 'bottom':
                    return 'translateY(50px)'
                case 'left':
                    return 'translateX(-50px)'
                // case 'right':
                default:
                    return 'translateX(50px)'
            }
        }};
    }
    & > .header {
        display: flex;
        justify-content: space-between;
        & > h3 {
            flex: 1;
            font-size: 17px;
            font-weight: 400;
            margin: 0;
        }
        & > .buttons {
            align-items: flex-end;
            display: flex;
            & > button {
                background-color: transparent;
                border: none;
                border-radius: 3px;
                cursor: pointer;
                padding: 4px 8px;
                transition: background-color 200ms;
                user-select: none;
                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                }
                &:not(:first-child) {
                    margin-left: 8px;
                }
                &[disabled] {
                    cursor: not-allowed;
                    background-color: #f5f5f5;
                    color: #aaa;
                }
            }
        }
        & > h3 + .buttons {
            margin-left: 12px;
        }
    }
    & > .header + .content {
        margin-top: 12px;
    }
    @media (max-width: 1023px) {
        padding: 16px;
    }
`
