import styled from 'styled-components'
import Card from '@consumer-dashboard/components/common/Card'

export default styled(Card)`
    & > .content {
        align-items: center;
        display: flex;
        flex-direction: column;
        & > .ant-select {
            max-width: 300px;
            width: 100%;
        }
    }
    @media (max-width: 767px) {
        & > .header {
            text-align: center;
        }
    }
`
