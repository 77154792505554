import PropTypes from 'prop-types'
import { t } from 'ttag'
import ExplanationCard from './ExplanationCard'

export default function HelpCard({ delay }) {
    return (
        <ExplanationCard
            delay={delay}
            customTitle={t`Having problems logging in?`}
            customSubtitle={t`Try these steps to make it work`}
            customSteps={[
                {
                    title: t`Try again`,
                    description: [t`Because we're just humans. Maybe you made a typo?`]
                },
                {
                    title: t`Check your spam folder`,
                    description: [
                        t`Some email clients put our emails in there.`,
                        t`Sorry, this is out of our control.`
                    ]
                },
                {
                    title: t`Check if the email you used is known to your installer`,
                    description: [
                        t`Not sure which one that is?`,
                        t`Check the first email you got from your installer about your dashboard.`,
                        t`That's the one!`
                    ]
                },
                {
                    title: t`Contact your installer`,
                    description: [
                        t`If none of these worked, ask your installer to help you out.`
                    ]
                }
            ]}
        />
    )
}

HelpCard.propTypes = {
    delay: PropTypes.number
}

HelpCard.defaultProps = {
    delay: 0
}
