import styled from 'styled-components'
import Card from '@consumer-dashboard/components/common/Card'

export default styled(Card)`
    & > .content {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        & > .installer-image {
            max-height: 200px;
            max-width: 100%;
        }
        & > section {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            & > p {
                margin: 0;
            }
            & > :not(:first-child) {
                margin-top: 8px;
            }
            & > .name {
                font-weight: 700;
            }
        }
        & > section,
        & > .installer-image {
            & + section {
                margin-top: 32px;
            }
        }
    }
    @media (max-width: 767px) {
        & > .content > .installer-image {
            height: 200px;
            object-fit: cover;
            width: 100%;
        }
    }
`
