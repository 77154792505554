import styled from 'styled-components'

export default styled.div`
    font-size: 15px;
    & > .cards {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        & > .card-group {
            display: flex;
            flex-basis: calc(34% - 8px);
            flex-direction: column;
            margin-right: 16px;
            & > .account-select {
                margin-bottom: 16px;
            }
        }
        & > .explanation {
            flex: 1;
        }
    }
    @media (max-width: 1023px) {
        & > .cards > .card-group {
            flex-basis: calc(34% - 6px);
            margin-right: 12px;
            & > .account-select {
                margin-bottom: 12px;
            }
        }
    }
    @media (max-width: 767px) {
        & > .cards {
            & > .card-group,
            & > .explanation {
                flex-basis: 100%;
            }
            & > .card-group {
                margin-bottom: 8px;
                margin-right: 0;
                & > .account-select {
                    margin-bottom: 8px;
                }
            }
        }
    }
`
