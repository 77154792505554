export default (
    <svg
        className="plane-kpi-icon"
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M37.508 8.62C36.5056 8.63313 35.5032 9.0236 34.7386 9.78811L28.8847 15.6286L12.8986 12.0324C12.5 11.9438 12.098 11.9372 11.7174 12.0061C10.5772 12.2112 9.61085 13.0758 9.43365 14.2767C9.32045 15.0445 9.56982 15.796 10.0111 16.3373C10.4525 16.8787 11.03 17.2692 11.6911 17.6498L21.2986 23.1888L16.2192 28.2548L9.84021 28.4123C9.45466 28.4205 9.12327 28.6912 9.0396 29.0686L8.64585 30.8798C8.58351 31.1587 8.66718 31.4508 8.86898 31.6542L18.345 41.1303C18.5485 41.3321 18.8405 41.4157 19.1194 41.3534L20.9175 40.9596C21.2949 40.876 21.5656 40.5446 21.5738 40.159L21.7313 33.7671L26.7973 28.6876L32.3493 38.3081C32.7299 38.9693 33.1073 39.5468 33.6487 39.9881C34.1901 40.4294 34.9415 40.6657 35.7093 40.5525C37.3105 40.3162 38.3113 38.6953 37.9537 37.1006L34.3706 21.1011L40.1981 15.2606C41.7271 13.7315 41.778 11.2476 40.2637 9.73508C39.5074 8.97874 38.5099 8.60633 37.5075 8.61948L37.508 8.62ZM37.5212 10.2869C38.0888 10.2787 38.6417 10.4887 39.0699 10.9169C39.928 11.775 39.9263 13.158 39.0174 14.0669V14.08L20.3144 32.8225C20.1635 32.9718 20.0732 33.1736 20.065 33.3869L19.9075 39.4639L19.1988 39.6214L10.3788 30.7884L10.5363 30.0796L16.6002 29.9221C16.8152 29.9205 17.0235 29.8352 17.1777 29.6859L35.9202 10.9829C36.3747 10.5284 36.9538 10.2954 37.5215 10.2873L37.5212 10.2869ZM12.2556 13.6338C12.3459 13.6387 12.4345 13.6518 12.5313 13.6732L27.4808 17.0332L22.5327 21.9551L12.5317 16.1932C11.9378 15.8503 11.5194 15.5435 11.3111 15.2876C11.1028 15.0317 11.0486 14.884 11.1011 14.5263C11.1848 13.957 11.6261 13.5977 12.2561 13.6338L12.2556 13.6338ZM32.9667 22.5063L36.3136 37.4688C36.4891 38.2481 36.1233 38.8043 35.4736 38.8994C35.1159 38.9519 34.9666 38.8945 34.7123 38.6894C34.458 38.481 34.1479 38.0627 33.8067 37.4688L28.0317 27.4543L32.9667 22.5063Z"
            fill="#757575"
        />
    </svg>
)
