import { useState } from 'react'
import PropTypes from 'prop-types'
import { t } from 'ttag'
import classNames from 'classnames'
import StyledCard from './LoginCard.styled'
import Image from '@consumer-dashboard/components/common/Image'
import { PRELOADED_IMAGES } from '@consumer-dashboard/utils/constants'
import { sendMagicLink } from '@consumer-dashboard/utils/api'
import LoadingOverlay from '@consumer-dashboard/components/common/LoadingOverlay'

export default function LoginCard({ delay, onLinkSent, isHelpOpen, setHelp }) {
    const [email, setEmail] = useState('')
    const [emailTouched, setEmailTouched] = useState(false)
    const [loading, setLoading] = useState(false)
    const validEmail = /[^@]+@[^@]+\.[^@]+/.test(email)

    const handleSetEmail = (newEmail) => {
        setEmailTouched(true)
        setEmail(newEmail)
    }

    const onSendClick = () => {
        setLoading(true)

        sendMagicLink(email)
            .then(() => {
                setLoading(false)
                onLinkSent()
            })
            .catch(() => setLoading(false))
    }

    return (
        <StyledCard className="log-in" delay={delay} data-testid="login-card">
            <Image
                src={PRELOADED_IMAGES['magic-link']}
                alt={t`Magic link image`}
                className="magic-link-image"
            />
            <h3 className="title">{t`Log in`}</h3>
            <p>{t`This happens with a magic link!`}</p>
            <p>{t`Enter your email address below:`}</p>
            <div className="log-in-form">
                <input
                    type="email"
                    className={classNames('email-input', {
                        invalid: !validEmail,
                        touched: emailTouched
                    })}
                    placeholder={t`name@example.com`}
                    value={email}
                    onChange={({ target: { value } }) => handleSetEmail(value)}
                    data-testid="login-email-input"
                />
                {!validEmail && emailTouched && (
                    <p
                        className="error"
                        data-testid="login-email-error"
                    >{t`Invalid email`}</p>
                )}
                <button
                    type="button"
                    className="send-button brand-button"
                    onClick={onSendClick}
                    disabled={!validEmail}
                    data-testid="login-email-send-button"
                >{t`Send link`}</button>
            </div>
            <div className="help">
                <p className="title">
                    {isHelpOpen ? t`Let's try again?` : t`Not working?`}
                </p>
                <button
                    type="button"
                    className="brand-link"
                    onClick={() => setHelp(!isHelpOpen)}
                    data-testid={
                        isHelpOpen ? 'login-try-again-button' : 'login-help-button'
                    }
                >
                    {isHelpOpen ? t`Back to login steps` : t`Help with login`}
                </button>
            </div>
            <LoadingOverlay loading={loading} />
        </StyledCard>
    )
}

LoginCard.propTypes = {
    delay: PropTypes.number,
    onLinkSent: PropTypes.func.isRequired,
    isHelpOpen: PropTypes.bool.isRequired,
    setHelp: PropTypes.func.isRequired
}

LoginCard.defaultProps = {
    delay: 0
}
