import { t } from 'ttag'
import StyledMaintenanceMode from './NoData.styled'
import Image from './Image'
import { PRELOADED_IMAGES } from '@consumer-dashboard/utils/constants'

export default function MaintenanceMode() {
    return (
        <StyledMaintenanceMode data-testid="maintenance-mode-screen">
            <Image
                src={PRELOADED_IMAGES['maintenance-mode']}
                alt={t`Maintenance mode image`}
                className="maintenance-mode-image"
            />
            <h2>{t`Maintenance mode...`}</h2>
            <p className="description">
                {t`We're working with the data from your system. Please come back later.`}
            </p>
        </StyledMaintenanceMode>
    )
}
