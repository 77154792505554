import styled from 'styled-components'

export default styled.article`
    color: #757575;
    margin-top: 20px;
    .title {
        color: #454444;
        font-family: 'Montserrat', sans-serif;
        font-size: 25px;
        font-weight: 500;
        margin: 0;
    }
    & > .element {
        display: flex;
        & > .image-container {
            flex-basis: calc(20% - 10px);
            margin-right: 20px;
            & > .image {
                width: 100%;
            }
        }
        & > .info {
            flex: 1;
            & > .description,
            & > .specsheet {
                margin: 12px 0 0;
            }
            & > .specsheet {
                display: inline-block;
            }
        }
    }
    @media (max-width: 1023px) {
        & > .element > .image-container {
            flex-basis: calc(20% - 8px);
            margin-right: 16px;
        }
    }
    @media (max-width: 767px) {
        & > .element {
            margin-top: 12px;
            & > .info > .description {
                margin-top: 0;
            }
        }
    }
`
