export default (
    <svg
        className="energy-kpi-icon"
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M27.5016 7.00026C27.2919 7.00686 27.0975 7.10582 26.973 7.26911L12.1339 26.7603C11.9805 26.9615 11.9566 27.2287 12.0742 27.4514C12.1919 27.674 12.4289 27.8142 12.6881 27.8126H20.8V43.3337C20.7966 43.6207 20.9859 43.8764 21.2673 43.9671C21.5486 44.0562 21.859 43.9605 22.0329 43.7279L36.8668 24.2368C37.0203 24.0372 37.0424 23.77 36.9265 23.5473C36.8105 23.3247 36.5752 23.1845 36.3177 23.1845H28.2058V7.66332C28.2075 7.48353 28.1325 7.31199 27.9995 7.18664C27.8664 7.06293 27.6874 6.99531 27.5015 7.00025L27.5016 7.00026ZM26.8417 9.66408V23.8424C26.8417 24.0173 26.9116 24.1855 27.0395 24.3092C27.1674 24.4346 27.3414 24.5039 27.5221 24.5055H34.9692L22.1644 41.3314V27.1497C22.1627 26.7868 21.8591 26.4932 21.484 26.4915H14.0321L26.8417 9.66408Z"
            fill="#757575"
            stroke="#757575"
            strokeWidth="0.4"
        />
    </svg>
)
