export default (
    <svg
        className="energy-icon"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.481 27.777C11.3849 27.7594 11.2912 27.729 11.2021 27.6868C10.7638 27.4782 10.5189 27.0012 10.6021 26.5231L12.0658 18.2215L6.74678 17.2828C6.36476 17.2137 6.04835 16.9442 5.91944 16.5762C5.78936 16.2094 5.86787 15.8004 6.12334 15.5074L17.1156 2.96144C17.4367 2.59934 17.9582 2.49386 18.3942 2.70246C18.8301 2.91222 19.0738 3.38448 18.993 3.86145L17.5293 12.1618L22.8507 13.1005L22.8519 13.1017C23.2339 13.1708 23.5503 13.4404 23.6792 13.8072C23.8081 14.1751 23.7308 14.5829 23.4753 14.8771L12.483 27.4267C12.2334 27.7103 11.8537 27.8439 11.4811 27.7771L11.481 27.777ZM9.01537 15.477L13.5154 16.2692C13.8013 16.3172 14.0556 16.4778 14.222 16.7157C14.3884 16.9524 14.4529 17.2465 14.4002 17.5325L13.4076 23.1002L20.5866 14.8982L16.089 14.1001C15.8065 14.0509 15.5558 13.8915 15.3917 13.6572C15.2265 13.4216 15.1632 13.131 15.2136 12.8486L16.2003 7.29616L9.01537 15.477Z"
            fill="#757575"
        />
    </svg>
)
