export default (
    <svg
        className="installer-icon"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M25.6315 6.40656C25.3164 6.35224 24.9937 6.45436 24.7689 6.68141L22.4951 8.97693C22.4429 9.03016 22.3821 9.04102 22.3408 9.04102C22.2995 9.04102 22.2398 9.03016 22.1876 8.97693L21.149 7.93834C21.0654 7.85469 21.0643 7.71781 21.1469 7.63306L23.4739 5.23976C23.6923 5.01597 23.79 4.70092 23.7368 4.39238C23.6836 4.08384 23.4858 3.81986 23.2055 3.68189C20.6254 2.40974 17.4728 2.95076 15.3608 5.0279L15.3554 5.03333C14.3831 6.00564 13.7237 7.21479 13.4488 8.5292C13.225 9.59821 13.263 10.6944 13.5542 11.7372L3.99295 21.2985C3.35306 21.9383 3 22.7901 3 23.695C3 24.6 3.35306 25.4517 3.99295 26.0916C4.65346 26.7521 5.52148 27.0823 6.3895 27.0823C7.25534 27.0823 8.12228 26.7532 8.78279 26.0959L18.3137 16.5878C20.6733 17.2451 23.2338 16.5704 24.9917 14.7844C27.033 12.7409 27.5784 9.59593 26.3497 6.95692C26.215 6.66576 25.9467 6.46043 25.6316 6.40612L25.6315 6.40656ZM7.40202 24.7108C6.84254 25.2681 5.93434 25.267 5.37591 24.7086C5.1054 24.4381 4.95549 24.0775 4.95549 23.6951C4.95549 23.3116 5.10433 22.952 5.37591 22.6815L7.58777 20.4696L9.61821 22.5001L7.40202 24.7108ZM23.6065 13.405C23.6044 13.4072 23.6022 13.4093 23.6 13.4115C22.2344 14.801 20.1942 15.2518 18.4007 14.5598C18.04 14.4207 17.6315 14.5066 17.3578 14.7803L11.0035 21.1194L8.97084 19.0867L15.3619 12.6957C15.6356 12.4219 15.7215 12.0135 15.5824 11.6528C14.8828 9.83747 15.3238 7.8332 16.734 6.42094C17.891 5.2846 19.4727 4.78378 20.9718 5.00974L19.7442 6.27212C18.9208 7.1195 18.9305 8.48835 19.7659 9.32274L20.8045 10.3613C21.2152 10.772 21.7605 10.998 22.3407 10.998H22.3461C22.9284 10.9969 23.4748 10.7676 23.8844 10.3537L25.0001 9.22715C25.2054 10.7405 24.7122 12.2995 23.6063 13.4052L23.6065 13.405Z"
            fill="#757575"
        />
    </svg>
)
