import styled from 'styled-components'

export default styled.section`
    align-items: stretch;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    & > .card {
        & > .content {
            display: flex;
            justify-content: space-around;
            & > .kpi {
                align-items: center;
                display: flex;
                flex: 1;
                flex-direction: column;
                text-align: center;
                &:not(:last-child) {
                    margin-right: 48px;
                }
                & > .title,
                & > .value,
                & > .description {
                    margin: 0;
                }
                & > .title {
                    color: #303030;
                    font-size: 17px;
                    font-weight: 400;
                    margin-bottom: 12px;
                }
                & > svg {
                    margin-bottom: 8px;
                    path {
                        fill: ${({ theme }) => theme.brandColor};
                    }
                    &.energy-kpi-icon path {
                        stroke: ${({ theme }) => theme.brandColor};
                    }
                }
                & > .value {
                    font-size: 20px;
                    font-weight: 700;
                    margin-bottom: 8px;
                }
                & > .description {
                    color: #757575;
                }
                &.main > .value {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 35px;
                    font-weight: 500;
                }
            }
        }
        &.empty > .content {
            flex-wrap: wrap;
            & > .kpi {
                flex: 1;
                margin: 0;
                text-align: center;
            }
            & > .empty-state {
                flex-basis: 100%;
            }
        }
    }
    @media (max-width: 1023px) {
        & > .card > .content > .kpi:not(:last-child) {
            margin-right: 30px;
        }
    }
    @media (max-width: 767px) {
        & > .card > .content > .kpi {
            &:not(:last-child) {
                margin-right: 12px;
            }
            &.main > .value {
                font-size: 28px;
            }
        }
    }
`
