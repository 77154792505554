import { useSelector } from 'react-redux'
import { t } from 'ttag'
import classNames from 'classnames'
import Card from '@consumer-dashboard/components/common/Card'
import StyledKpiCards from './KpiCards.styled'
import { getDashboard } from '@consumer-dashboard/redux/selectors'
import { USER_LANGUAGE } from '@consumer-dashboard/utils/constants'
import { createFormatters } from 'generic/generic.utils'
import EnergyIcon from '@consumer-dashboard/svgs/energy-kpi-icon'
import PigIcon from '@consumer-dashboard/svgs/pig-kpi-icon'
import LeafIcon from '@consumer-dashboard/svgs/leaf-kpi-icon'
import CarIcon from '@consumer-dashboard/svgs/car-kpi-icon'
import PlaneIcon from '@consumer-dashboard/svgs/plane-kpi-icon'
import EmptyState from '@consumer-dashboard/components/common/EmptyState'

export default function KpiCards() {
    const { systems, selectedSystem } = useSelector(getDashboard)
    const { formatCurrency, formatInteger } = createFormatters(USER_LANGUAGE)
    const formattedKwhPrice = formatCurrency(systems[selectedSystem].kwhPrice)

    const {
        totalProduction,
        totalSaved,
        reductionCo2,
        reductionKm,
        reductionFlights
    } = systems[selectedSystem].energy

    return (
        <StyledKpiCards className="kpis">
            <Card
                className={classNames('total-kpis', {
                    empty: !totalProduction && !totalSaved
                })}
                delay={200}
                data-testid="total-kpis-card"
            >
                {totalProduction || totalSaved ? (
                    <>
                        <div className="kpi main">
                            <h3 className="title">{t`Total produced`}</h3>
                            {EnergyIcon}
                            <p className="value" data-testid="kpi-total-production">
                                {formatInteger(totalProduction)}
                            </p>
                            <p className="description">{t`kWh`}</p>
                        </div>
                        <div className="kpi main">
                            <h3 className="title">{t`Total saved`}</h3>
                            {PigIcon}
                            <p className="value" data-testid="kpi-total-saved">
                                &euro;{formatInteger(totalSaved)}
                            </p>
                            <p className="description">{t`Based on €${formattedKwhPrice} per kWh`}</p>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="kpi">
                            <h3 className="title">{t`Total produced`}</h3>
                        </div>
                        <div className="kpi">
                            <h3 className="title">{t`Total saved`}</h3>
                        </div>
                        <EmptyState height="114px" />
                    </>
                )}
            </Card>
            <Card
                title={t`Total CO2 reduction`}
                className="reduction-kpis"
                delay={400}
                data-testid="reduction-kpis-card"
            >
                {reductionCo2 || reductionKm || reductionFlights ? (
                    <>
                        <div className="kpi">
                            {LeafIcon}
                            <p className="value" data-testid="kpi-reduction-co2">
                                {formatInteger(reductionCo2)}
                            </p>
                            <p className="description">{t`Savings in kg CO2`}</p>
                        </div>
                        <div className="kpi">
                            {CarIcon}
                            <p className="value" data-testid="kpi-reduction-km">
                                {formatInteger(reductionKm)}
                            </p>
                            <p className="description">{t`Driven km`}</p>
                        </div>
                        <div className="kpi">
                            {PlaneIcon}
                            <p className="value" data-testid="kpi-reduction-flights">
                                {formatInteger(reductionFlights)}
                            </p>
                            <p className="description">{t`From Paris -> Amsterdam`}</p>
                        </div>
                    </>
                ) : (
                    <EmptyState height="112px" />
                )}
            </Card>
        </StyledKpiCards>
    )
}
