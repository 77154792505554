import { useSelector } from 'react-redux'
import { t } from 'ttag'
import styled from 'styled-components'
import { getDashboard } from '@consumer-dashboard/redux/selectors'
import Card from '@consumer-dashboard/components/common/Card'
import { extractPhoneNumber } from '@consumer-dashboard/utils/utils'

const StyledCard = styled(Card)`
    & > .content > :not(:first-child) {
        margin-top: 12px;
    }
`

export default function ContactCard() {
    const { systems, selectedSystem } = useSelector(getDashboard)
    const { phone, email } = systems[selectedSystem]?.installer

    if (!phone && !email) {
        return null
    }

    return (
        <StyledCard
            title={t`Direct contact`}
            className="contact"
            delay={400}
            data-testid="contact-card"
        >
            {!!phone && (
                <a
                    href={`tel:${extractPhoneNumber(phone)}`}
                    className="brand-button"
                    data-testid="call-button"
                >{t`Call us`}</a>
            )}
            {!!email && (
                <a
                    href={`mailto:${email}`}
                    className="brand-button"
                    data-testid="email-button"
                >{t`Send us an email`}</a>
            )}
        </StyledCard>
    )
}
