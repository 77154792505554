import PropTypes from 'prop-types'
import { t } from 'ttag'
import styled from 'styled-components'
import SadFaceIcon from '@consumer-dashboard/svgs/sad-face-icon'

const StyledEmptyState = styled.div`
    align-items: center;
    background-color: #f2f2f2;
    color: #454444;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    justify-content: center;
    & > :not(:first-child) {
        margin-top: 12px;
    }
`

export default function EmptyState({ height, width, minHeight }) {
    return (
        <StyledEmptyState className="empty-state" style={{ height, width, minHeight }}>
            {SadFaceIcon}
            <span>{t`No data here`}...</span>
        </StyledEmptyState>
    )
}

EmptyState.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
    minHeight: PropTypes.string
}

EmptyState.defaultProps = {
    height: '110px',
    width: '100%',
    minHeight: 'auto'
}
