export default (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M38.5564 16.7236C36.3736 9.20637 29.3908 3.57517 20.786 2.37837C19.2641 2.04243 17.6985 1.95961 16.15 2.13149C15.9578 2.15805 15.7969 2.17368 15.6547 2.18774H15.6531C15.0328 2.19711 14.4344 2.4268 13.9672 2.83618C13.7578 2.99399 13.6813 3.2737 13.7797 3.51742C9.50771 4.95178 6.15331 6.77522 3.74691 10.5502C0.925031 14.9798 0.0406707 19.9066 1.32503 24.0674C2.58751 28.1266 5.12347 31.669 8.55783 34.1722C11.9937 36.6753 16.1438 38.005 20.3938 37.9644C20.9641 37.9644 21.5454 37.941 22.1204 37.8941C28.0828 37.405 33.4828 34.4441 36.564 29.9705C39.2515 26.0705 39.939 21.4893 38.5562 16.7237L38.5564 16.7236ZM34.8908 28.6144C32.4158 32.4847 28.0344 35.1908 23.1672 35.8552C13.8312 37.1239 4.83597 30.9488 3.12357 22.0832C2.45325 18.6878 3.19545 15.1644 5.17669 12.3268C7.75325 8.53457 12.2439 5.71437 17.1767 4.78477V4.78633C17.9048 4.67383 18.6408 4.61758 19.3783 4.61758C26.7438 4.61758 34.2126 10.152 36.3814 17.2176C37.608 21.0488 37.0611 25.2284 34.8892 28.6144L34.8908 28.6144Z"
            fill="#BDBDBD"
        />
        <path
            d="M12.7376 19.9205C12.9767 20.1095 13.2736 20.2095 13.5798 20.2033C13.7314 20.2017 13.883 20.183 14.0314 20.147C14.822 19.9908 15.3923 19.2986 15.3955 18.4923C15.4033 17.5658 15.0736 16.6673 14.4674 15.9673C14.4689 15.858 14.4252 15.7533 14.347 15.6783C13.7752 15.1158 13.3127 15.1408 13.0267 15.2595C12.0517 15.6673 12.0189 17.6142 12.0189 17.633C12.0189 18.3689 12.0908 19.4064 12.7377 19.9205L12.7376 19.9205Z"
            fill="#BDBDBD"
        />
        <path
            d="M26.5264 15.4671C26.5202 15.4593 26.5123 15.4515 26.5061 15.4437C26.2467 15.1078 25.803 14.9749 25.4014 15.1125C25.078 15.2687 24.8202 15.5359 24.6764 15.8656L24.6592 15.8796H24.6577C24.5545 15.964 24.5014 16.0937 24.5124 16.2265C24.353 16.6406 24.2467 17.0734 24.1967 17.514C24.167 18.239 24.3561 19.5718 25.2639 19.9734C25.4217 20.0422 25.5905 20.0781 25.7624 20.0796C26.2139 20.0796 26.8264 19.8171 27.3593 18.6765C27.8639 17.5968 27.5546 16.3984 26.5327 15.4718L26.5264 15.4671Z"
            fill="#BDBDBD"
        />
        <path
            d="M26.6408 24.5876C21.472 23.4876 16.1064 23.8345 12.0516 27.5454C11.0531 28.4595 12.5407 29.9423 13.5359 29.0297C17.0016 25.8579 21.6923 25.6766 26.0811 26.611C27.3999 26.8938 27.9639 24.8703 26.6405 24.5875L26.6408 24.5876Z"
            fill="#BDBDBD"
        />
    </svg>
)
