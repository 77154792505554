export default (
    <svg
        className="pig-kpi-icon"
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.8328 25.3328C5.37344 25.3328 5 25.7062 5 26.1672V32.8328C5 33.2937 5.37344 33.6671 5.8328 33.6671L7.87344 33.664C9.01408 35.9234 10.9094 37.8844 13.3326 39.3392V42.833C13.3326 43.2939 13.7061 43.6673 14.167 43.6673H19.167C19.6264 43.6673 19.9998 43.2939 19.9998 42.833L20.0029 41.7345C21.0904 41.9111 22.2045 42.0001 23.3326 42.0001C24.4638 42.0001 25.5779 41.9111 26.667 41.7345V42.833C26.667 43.2939 27.0388 43.6673 27.4998 43.6673H32.4998C32.9607 43.6673 33.3326 43.2939 33.3326 42.833L33.3404 39.3345C37.4824 36.8486 39.9996 32.9393 39.9996 28.6673C39.9996 21.2533 32.4996 15.3329 23.3324 15.3329C21.1777 15.3329 19.0792 15.6595 17.1232 16.2876L12.928 13.786C12.3733 13.4517 11.6671 13.8517 11.6671 14.5001L11.6639 19.1409C9.50457 20.8331 7.94985 22.9675 7.19033 25.3301L5.8328 25.3328ZM8.62344 26.3734C9.22812 24.0234 10.7594 21.8766 12.997 20.2202C13.208 20.0624 13.333 19.814 13.333 19.5499L13.3377 15.9734L16.5783 17.9187C16.7892 18.0452 17.0455 18.0718 17.2783 17.9921C19.1689 17.3406 21.2205 16.9999 23.3331 16.9999C31.6551 16.9999 38.3331 22.2719 38.3331 28.6671C38.3331 32.4421 35.9956 35.9423 32.0923 38.1327C31.8298 38.2811 31.6673 38.5593 31.6673 38.8593V41.9999H28.3329V40.7296C28.3329 40.1984 27.8439 39.803 27.3251 39.914C26.0361 40.1921 24.697 40.3327 23.3329 40.3327C21.9705 40.3327 20.6314 40.1921 19.3423 39.914C18.8235 39.803 18.3329 40.1983 18.3329 40.7296V41.9999H15.0001V38.8593C15.0001 38.5593 14.8376 38.2812 14.5751 38.1327C12.0689 36.7265 10.1735 34.7515 9.16114 32.4919C9.02677 32.1935 8.7299 31.9997 8.40178 31.9997H6.66742V26.9997H7.81742C8.19711 26.9997 8.5299 26.7419 8.62366 26.3732L8.62344 26.3734Z"
            fill="#757575"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.6672 31.672C43.5079 31.672 45 30.1798 45 28.3392C45 27.3954 44.6047 26.5142 43.9266 25.8876C43.5875 25.5751 43.061 25.597 42.7485 25.9345C42.436 26.2736 42.4579 26.8002 42.7954 27.1126C43.1376 27.4267 43.3329 27.8658 43.3329 28.3392C43.3329 29.2595 42.5876 30.0048 41.6672 30.0048C40.7469 30.0048 40 29.2595 40 28.3392C40 27.8783 39.6266 27.5048 39.1672 27.5048C38.7063 27.5048 38.3329 27.8783 38.3329 28.3392C38.3329 30.1798 39.8251 31.672 41.6672 31.672L41.6672 31.672Z"
            fill="#757575"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.1563 23.592C32.5767 23.7826 33.0704 23.5951 33.2595 23.1763C33.4485 22.756 33.2626 22.2639 32.8438 22.0732L27.3938 19.6123C26.9751 19.4232 26.4813 19.6092 26.2923 20.0295C26.1016 20.4483 26.2891 20.942 26.7079 21.1311L32.1563 23.592Z"
            fill="#757575"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 25.3328C15 27.5562 11.6672 27.5562 11.6672 25.3328C11.6672 23.1109 15 23.1109 15 25.3328Z"
            fill="#757575"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.5 12.5C32.1569 12.5 33.5 11.1569 33.5 9.5C33.5 7.84315 32.1569 6.5 30.5 6.5C28.8431 6.5 27.5 7.84315 27.5 9.5C27.5 11.1569 28.8431 12.5 30.5 12.5ZM30.5 14C32.9853 14 35 11.9853 35 9.5C35 7.01472 32.9853 5 30.5 5C28.0147 5 26 7.01472 26 9.5C26 11.9853 28.0147 14 30.5 14Z"
            fill="#757575"
        />
    </svg>
)
