import styled from 'styled-components'
import Card from '@consumer-dashboard/components/common/Card'

export default styled(Card)`
    align-items: flex-start;
    display: flex;
    & > .content {
        & > .explanation-header {
            font-family: 'Montserrat', sans-serif;
            font-weight: 500;
            & > h3 {
                color: #757575;
                font-size: 18px;
                margin: 0;
            }
            & > h2 {
                font-size: 25px;
                margin: 12px 0 0;
            }
        }
        & > .steps {
            display: flex;
            flex-direction: column;
            margin: 40px 0 24px;
            & > .step {
                align-items: flex-start;
                display: flex;
                &:not(:first-child) {
                    margin-top: 24px;
                }
                & > .step-number {
                    font-size: 18px;
                    text-align: right;
                }
                & > .step-text {
                    flex: 1;
                    margin-left: 12px;
                    & > .step-title {
                        font-weight: 700;
                        margin: 0;
                    }
                    & > .step-description {
                        margin: 8px 0 0;
                    }
                }
            }
        }
    }
    @media (max-width: 767px) {
        justify-content: center;
        & > .content > .explanation-header {
            text-align: center;
        }
    }
`
